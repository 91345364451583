.policy-container {

  
    margin: 0px auto;
    padding: 20px 50px;
    background: #fff;
    border-radius: 8px;
  min-height: 100vh;
    h2 {
      text-align: start;
      color: #222;
      font-family: 'SF UI Text Semibold';
      font-size: 22px;
      margin-bottom: 16px;
    }
  
    h3 {
      color: #222;
      margin-top: 20px;
      font-size: 16px;
      font-family: 'SF UI Text Semibold';
    }
    h4{
        color: #222;
        margin-top: 20px;
        font-size: 14px;
        font-family: 'SF UI Text Semibold';
    }
  
    p {
      color: #222;
      line-height: 1.6;
    }
  
    a {
      color: #007bff;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      padding-left: 20px;
      list-style: disc;
    }
  }