.budget-white-sec {
    background-color: #fff;
    padding: 32px;
    border-radius: 12px;
}

.budget-section-card {
    margin: auto;
    height: auto;
    min-height: 291px;
    width: 100%;
    border-radius: 0.5rem;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 1px 3px #ffffff26;
    background-color: #f0f0f0;
    padding: 16px;
    background: url('https://cdn.pixabay.com/photo/2016/08/05/09/31/banner-1571858_1280.jpg');
    background-size: cover;
}

.budget-section-card-url {
    padding: 0px;
    background-color: transparent;
    background: none;
    width: 510px;
}

.budget-ht-crad-text {
    margin-top: 5px;
    font-size: 13px;
    font-family: 'SF UI Text Medium';
}

.budget-h1 {
    font-size: 2rem;
    font-weight: 900;
    position: relative;
    color: #41303e;
    font-family: 'SF UI Text Bold';
    line-height: 42px;
    padding-bottom: 3rem;
}

.budget-icon1 {
    height: 201px;
}

.budget-icon1 img {
    max-height: 100%;
    max-width: 100%;
}

.budget-icon2 {
    height: 201px;
}

.budget-icon2 img {
    max-height: 100%;
    max-width: 100%;
}

.budget-text {
    font-family: "'SF UI Text Medium'";
    font-size: 1.8rem;
    font-weight: 600;
    color: #1a374d;
}

.budget-img {
    width: auto;
    position: relative;
    height: 286px;
}

.budget-img img {
    height: 100%;
    max-width: 100%;
}

.budget-tool {
    width: 100%;
    position: relative;
}

.budget-tool img {
    width: 100%;
}

.budget-adv-img {
    display: grid;
    align-items: center;
    align-content: center;
    position: absolute;
    left: 0;
    top: 2rem;
}

.budget-in-mind {
    height: 30px;
}

.budget-adv-img img {
    max-width: 100%;
    max-height: 88%;
}

.budget-content {
    position: absolute;
    left: 30rem;
    bottom: 70px;
    z-index: 2;
}

.budget-msg-top {
    display: flex;
    font-size: 0.8rem;
}

.budget-msg-top img {
    height: 17px;
    margin-right: 15px;
}

.budget-p {
    padding-left: 33px;
    font-size: 0.9rem;
    color: black;
}

.budget-heding {
    font-size: 2.188rem;
    text-align: center;
    line-height: 42px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}

.budget-scroll-mob {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.budget-card-content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
}

.budget-card-content {
    justify-content: center;
    margin: auto;
    padding-bottom: 0;
}

.fist-sec-content-img {
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -16px;
    margin-bottom: -16px;
}

.budget-section-card-url .fist-sec-content-img {
    margin-top: 16px;
    margin-bottom: -14px;
    margin-left: 16px;
}

.fist-sec-budget-img {

    height: 339px;
    position: relative;
    z-index: 5;
    position: relative;
    bottom: -55px;
    left: 0;
}

.budget-section-card-url .fist-sec-budget-img {
    height: 314px;
}

.fist-sec-budget-img img {
    max-width: 100%;
    height: auto;
    z-index: 99;
    position: relative;
}

.first-act-card {
    width: 564px;
    max-width: 55%;
    display: flex;
    justify-content: center;
    z-index: 5;
}

.bg90deg {
    position: absolute;
    width: 167%;
    height: 119%;
    background-color: rgba(34, 34, 34, 0.2705882353);
    transform: rotate(45deg);
    left: -42px;
}

.first-act-card button {
    border: 1px solid #06c;
    padding: 12px 34px;
    background-color: #06c;
    border-radius: 8px;
    font-size: 18px;
    font-family: 'SF UI Text Medium';
    color: #fff;
    box-shadow: 5px 5px 13px #b6b7b7, -5px -5px 13px #e8e9e9;
}

.budget-card-description {
    width: fit-content;
    opacity: 1;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    padding: 16px;
    border-radius: 9px;
    max-width: 45%;
    margin-right: 1%;
    min-width: 349px;
}

.budget-card-description span {
    font-family: "SF UI Text Semibold";
    font-style: normal;
    font-size: 25px !important;
    line-height: 140%;
    color: #222;
    letter-spacing: -0.5px;
    margin-bottom: 3px;
    padding-right: 0px;
}

.budget-card-description p {
    color: #222;
    font-family: "SF UI Text Regular";
    font-size: 15px;
    line-height: 140%;
    letter-spacing: -0.5px;
}

.budget-card-action {
    max-width: 55%;
    height: -moz-fit-content;
    height: 350px;
    max-height: 100%;
    padding: 22px 32px 32px;
    border-radius: 14px;
    position: relative;
    background-color: #ffffffba;
    width: 564px;
}

.budget-card-action h4 {
    font-size: 18px;
    font-family: "SF UI Text Medium";
    text-align: center;
    margin-bottom: 24px;

}

.budget-card-action-url {
    width: fit-content;
    display: flex;
    padding-left: 0;
}

.budget-card-action-url .title {
    text-align: center;
    position: absolute;
    padding-left: 0;
    top: 73px;
    left: 17px;
}

.budget-card-action-popup {
    max-width: 100%;
    padding: 0px 0 32px;
    background-color: transparent;
}

.budget-card-action-popup .budget-ht-crad {
    padding: 24px 0 !important;
}

.budget-card-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffd1;
    left: 0;
    top: 0;
    border-radius: 22px;
}

.budget-card-loader span {
    color: #222;
    font-size: 13px;
    display: block;
    font-family: 'SF UI Text Medium';
}

.budget-card-header-strip {
    display: flex;
    justify-content: center;

}

.bug-back {
    display: flex;
    color: #06c;
    align-items: center;
    cursor: pointer;
    right: 33px;
    top: 24px
}

.bug-back i {
    margin-right: 8px;
    margin-top: 0px;
}

.power-head {
    margin-top: 8px;
    font-size: 11px;
    display: flex;
    justify-content: flex-end;
    color: #fff !important;
    position: absolute;
    bottom: 0;
    right: 4px;
}

.budget-card-action .title {
    color: #222;
    display: block;
    padding-bottom: 16px;
    padding-left: 0px;
    font-family: "SF UI Text Regular";
    font-size: 13px;
}

.budget-card-action-ht-ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    width: 75%;
    margin: auto;
    margin-bottom: 0px;
    margin-top: 34px;
}

.see-budget-ml-o {
    margin-left: auto;
}

.budget-card-action-ht-ul li {
    display: flex;
    margin: auto;
    width: 100%;
}

.budget-ht-crad {
    display: grid;
    justify-content: center;
    background: #fff;
    border: 1px solid #cdb6ff;
    padding: 24px;
    border-radius: 24px;
    cursor: pointer;
    width: 100%;
}
.budget-ht-crad span{
    color: #222 !important;
}
.budget-ht-crad:hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    background: #ede2fc;
    text-decoration: none;
    
}

.budget-ht-crad-img {
    width: 52px;
    height: 52px;
    margin: auto;
}

.budget-ht-crad img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.budget-ht-crad span {
    font-family: "SF UI Text Semibold";
    font-size: 14px;
    color: #222;
    text-align: center;
    margin-top: 8px;
}

.budget-ht-crad span:hover {
    color: #06c;
}

.budget-card-action-space-ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19%, 1fr));
    gap: 9px;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    width: 500px;
    margin: auto;
    margin-bottom: 0px;
    margin-top: 0px;
}

.budget-card-action-sl-ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    width: 100%;
    margin: auto;
    margin-bottom: 0px;
    margin-top: 32px;
    margin-left: 0px;
}

.spaces-ul-card {
    background: #fff;
    border: 1px solid #cdb6ff;
    border-radius: 6px;
    padding: 12px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 100%;
}

.spaces-ul-card img {
    height: 32px;
    margin-right: 8px;
}

.spaces-ul-card span {
    display: block;
    font-size: 13px;
    font-family: "SF UI Text Medium";
    color: #222;
}

.spaces-ul-card:hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    background: #ede2fc;
}

.active-spaces-ul-card {
    border-color: #1a374d;
}

.budget-sl-crad {
    display: grid;
    justify-content: center;
    background: #fff;
    border: 1px solid #cdb6ff;
    padding: 8px;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    max-width: 100%;
    min-width: 90%;
}

.budget-sl-crad:hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    background: #ede2fc;
}

.budget-sl-crad img {
    margin: auto;
    margin-bottom: 4px;
    margin-top: 4px;
    max-height: 38px;
}

.budget-sl-crad span {
    font-family: "SF UI Text Regular";
    font-size: 13px;
    color: #222;
}

.budget-card-img {
    height: 560px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
}

.budget-card-img img {
    width: 100%;
    height: 100%;
    margin: auto;
}

.budget-footer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 32px;
    margin: auto;
    width: fit-content;
}

.budget-footer button {
    text-align: center;
    color: #222 !important;
    cursor: pointer;
    padding: 8px 32px;
    border-radius: 26px;
    background: #FFcf80;
    border: 1px solid #ffbf56;
    font-family: "SF UI Text Medium";
}

.budget-section-card-url .budget-footer {
    bottom: 8px;
}

.budget-footer button:first-child {
    background: #eaeaea;
    color: #484848 !important;
    border: 1px solid #ddd;
    margin-right: 14px;

}

.budget-footer-url button {
    background: #06c;
    border-radius: 8px;
}

.budget-card-action .budget-button {
    left: 0rem !important;
    position: inherit;
    margin-top: 40px;
}

.form-group {
    text-align: center;
    width: 273px;
    margin: auto;
}

.area-img {
    margin-bottom: 16px;
}

.area-img img {
    height: 40px;
}

.input-group {
    width: 100%;
    max-width: 100%;
}

::ng-deep .form-group .p-inputtext::placeholder {
    font-size: 14px;
    font-family: "SF UI Text Regular";
    color: #838383;
    text-align: center;
}

::ng-deep .form-group .p-inputtext {
    width: 100%;
    text-align: center;
}

::ng-deep p-inputnumber,
.p-inputnumber {
    width: 100%;
}

.input-group span {
    color: #222;
    margin-left: 0px !important;
    font-family: 'SF UI Text Medium';
    font-size: 16px;
}

.error-carpet {
    position: absolute;
    left: 10px;
    color: red;
    top: 53px !important;
    right: 0;
    z-index: 99;
    font-size: 0.8rem;
    width: 100% !important;
    background-color: transparent;
}

.input-group input {
    border-radius: 8px !important;
    min-width: 273px;
    max-width: 273px;
}

.input-group .form-control {
    font-size: 1rem !important;
    line-height: 39px !important;
}

.budget-pop {
    border-top-right-radius: 18px !important;
    border-top-left-radius: 18px !important;
}

/* Works */
.work-sec {
    height: fit-content;
    padding: 20px 20px;
    background: #fff;
}

.budget-strip {
    padding: 0px 0 8px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
}

.bs-1 {
    display: flex;
}

.bs-1 span {
    display: flex;
    font-size: 12px;
    margin-right: 6px;
}

.bs-1 p {
    font-size: 14px;
    font-family: 'SF UI Text Semibold';
    letter-spacing: -0.5px;
    color: #222;
}

.total-budget-heading {
    font-size: 15px;
    font-family: 'SF UI Text Bold';
    margin-bottom: 14px;
    padding-left: 8px;
}


@media only screen and (max-width: 600px) {
    .budget-white-sec {
        background-color: transparent;
        padding: 0px;
        border-radius: 0px;
    }

    .web-bg {
        position: relative;
    }

    .plc {
        width: 100%;
    }

    .rup-sup {
        font-size: 13px;
        font-family: 'SF UI Text Regular';
    }

    .dealer-card-outer {
        margin-right: 16px;
        width: 100%;
    }

    .top-dealer-section .carousel-mobile li {
        width: 45%;
        flex: 0 0 45%;
    }

    .dealer-card {
        margin-right: 0px;
        border: 1px solid #ddd !important;
        box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.1215686275);
        color: inherit;
        border-radius: 9px !important;
    }

    .dealer-shoowroom-img {
        aspect-ratio: initial;
        height: 112px;
    }

    .launched-card-outer {
        margin-right: 0px;
        width: 100%;
        color: inherit;
    }

    .product-class-outer {
        display: flex;
        width: 100%;
    }

    .sec-menu-stripe {
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        justify-content: flex-start;
    }

    .sec-menu-stripe::-webkit-scrollbar {
        display: none;
    }

    .budget-scroll-mob {
        display: block;
        overflow: hidden;
        overflow-y: scroll;
    }

    .budget-section-card {
        width: 100%;
        padding: 0px 0px 0px 0px;
        border-radius: 13px;
        min-height: 224px;
    }

    .budget-section-card-url {
        background: transparent;
        flex-wrap: wrap;
    }

    .bg90deg {
        left: -22px;
        width: 137%;
        height: 196%;
    }

    .budget-scroll-mob .budget-card-description {
        padding-top: 0px !important;
        width: 100%;
        padding-left: 0;
    }

    .budget-scroll-mob::-webkit-scrollbar {
        display: none;
    }

    .fist-sec-content-img {
        height: 272px;
    }

    .fist-sec-budget-img {
        bottom: -81px;
    }

    .first-act-card {
        top: 63px;
        position: relative;
        z-index: 99;
    }

    .budget-card-description {
        width: 100%;
        padding-left: 0px;
        margin-bottom: 0px;
        padding-top: 0;
        padding-right: 0px !important;
    }

    .budget-card-description-popup {
        max-width: calc(100% - 32px);
        margin: auto;
        margin-top: 24px;
        padding-bottom: 24px;
    }

    .budget-card-description h2 {
        font-family: "SF UI Text Regular";
        font-size: 17px !important;
        line-height: 127%;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 16px;
        color: #222;
        padding-right: 0px !important;
    }

    .budget-card-action {
        min-width: 100%;
        border-radius: 0px;
        max-height: 100%;
        padding: 12px 0px 0px;
        background: #fff;
        overflow: hidden;
        overflow-y: scroll;
        height: 386px;
        width: 100%;
        border: 1px solid #e8e8e8;
        border-radius: 12px;
    }

    .budget-card-action-popup {
        padding: 24px 16px;
        background-color: transparent;
        height: 394px;
    }

    .mob-sec-pad-50 {
        padding-top: 16px;
        padding-bottom: 8px;
    }

    .budget-footer-mob {
        margin-top: 0px !important;
        border-top: 1px solid #e8e8e8;
        width: 100%;
        padding-top: 12px;
        bottom: 12px;
        padding-right: 12px;

    }

    .budget-footer {
        margin-top: 14.5rem;
    }

    .budget-card-action-popup .budget-footer-mob {
        padding: 12px 16px 16px;
        padding-top: 12px;
        background: #ffffff70;
        backdrop-filter: blur(13px);
        bottom: 0;
        border: none;
    }

    .budget-padig {
        padding-top: 50px;
    }

    .budget-card-action-space-ul {
        grid-template-columns: repeat(auto-fill, minmax(28%, 1fr));
        margin-top: 8px;
        width: 100%;
        padding: 8px;
    }

    .spaces-ul-card {
        padding: 10px 12px;
        border: 1px solid #cdb6ff !important;
        background: #ede2fc;
        box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.1215686275) !important;
    }

    .spaces-ul-card img {
        height: 24px;
    }

    .spaces-ul-card span {
        font-size: 13px;
        font-family: "SF UI Text Medium";
    }

    .budget-section .sec-head {
        text-align: center;
        margin-bottom: 4px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        font-size: 18px;
    }

    .sub-sec-heading {
        text-align: center;
        margin-bottom: 16px;
    }

    .budget-card-header-strip {
        justify-content: center;
        margin-bottom: 0px;
    }

    .budget-card-header-strip .one-step-house-typ {
        margin-bottom: 8px !important;
        padding: 0px !important;
    }

    .budget-card-action-ht-ul li {
        width: 100%;
    }

    .input-group {
        margin-top: 16px;
        width: 98%;

    }

    .input-group input {
        border-radius: 8px !important;
    }

    .input-group span {
        margin-right: 8px !important;
        line-height: inherit;
        font-family: "SF UI Text Medium";
        color: #222;

    }

    .budget-card-action .title {
        padding: 8px 0px;
        padding-bottom: 0px;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 0px;
        color: #666;
        font-family: "SF UI Text Medium";
    }

    .btn-bck-nxt {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12rem;
    }

    .bug-back {
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
        position: absolute;
        bottom: 4rem;
        left: 28px;
        top: 0rem;
        color: #06c;
        max-width: fit-content;
    }

    .budget-ht-crad {
        padding: 16px;
        width: 100%;
    }
    .budget-ht-crad-mob{
      max-width:70% ;
      margin: auto;
    }
    .budget-card-action-ht-ul {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        margin: auto;
        align-items: center;
        width: fit-content;
        margin-top: 55px;
    }

    .budget-card-action-url .title {
        top: 18px;
        left: 0;
        right: 0;
    }

    .budget-ht-crad {
        text-decoration: none !important;
        border: 1px solid #cdb6ff !important;
        background: #ede2fc;
        box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.1215686275) !important;
        border-radius: 14px;
    }

    .budget-ht-crad span {
        font-family: "SF UI Text Medium";
        font-size: 13px;
        color: #222;
        padding-top: 4px;
        line-height: 1;
    }

    .spaces-ul-card:hover {
        box-shadow: none !important;
        background: #ede2fc;
        border-color: #cdb6ff !important;
        text-decoration: none !important;
    }

    .budget-card-action-sl-ul {
        grid-template-columns: repeat(auto-fill, minmax(21%, 1fr));
        gap: 4px;
        width: 100%;
        margin-top: 8px;
        padding: 8px 4px;
    }

    .budget-sl-crad {
        background-color: #ede2fc;
        padding: 8px;
        width: 100%;
    }

    .budget-ht-crad span:hover {
        color: #222;
    }

    .budget-sl-crad span {
        color: #222;
        font-size: 12px;
    }

    .budget-sl-crad img {
        max-height: 32px;
        margin-bottom: 4px;
    }

    .area-img {
        width: fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
        margin-top: 16px;
        margin-bottom: 11px;
        background-color: #fff;
        padding: 12px;
        box-shadow: 2px 2px 7px 2px #ddd;
        border-radius: 8px;
    }

    .area-img img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    .budget-sl-crad:hover {
        box-shadow: none !important;
        text-decoration: none !important;
        border: 1px solid #cdb6ff;
        background: #ede2fc;
        text-decoration: none;
    }

    .budget-footer button {
        padding: 6px 28px;
        background: #FFcf80;
        color: #222 !important;
        font-size: 14px;
    }

    .budget-ht-crad img {
        height: 40px;
        margin: auto;
        width: auto;
        margin-bottom: 6px;
    }

    .budget-card-description span {
        font-size: 18px !important;
        max-width: 216px;
        margin: auto;
    }

    .input-sqft {
        position: absolute !important;
        right: 7px !important;
        top: 5px !important;
    }

    .budget-card-action::-webkit-scrollbar {
        display: none;
    }

    .work-sec-mob {
        padding: 16px 0px;
        background: transparent;
    }

    .work-card {
        background: #fff;
    }

    .budget-strip {
        padding: 8px 0;
        border: 1px solid #ddd;
        margin-bottom: 12px;
        margin-top: 12px;
        border-left: none;
        border-right: 0;
        display: flex;
        justify-content: space-between;
    }

    .total-budget-heading {
        margin-bottom: 0px;
        font-size: 13px;
        color: #838383;
        font-family: 'SF UI Text Medium';
    }

    .bs-1 {
        display: grid;
    }

    .bs-1 span {
        display: block;
        font-size: 12px
    }

    .bs-1 p {
        display: block;

    }

    .professionals-list .carousel-mobile li {
        width: 75%;
        flex: 0 0 75%;
        margin-right: 8px;
    }

    .plca {
        box-shadow: none;
    }

    .plcat span {
        font-size: 12px;
    }

    .plcat h3 {
        font-size: 14px;
    }

    .plcat p {
        font-size: 12px;
    }

    .plca-icon {
        height: 90px;
        width: 90px;

    }

    .budget-footer button:first-child {
        top: auto;
        bottom: 0px;
        color: #24272c !important;
        left: 0;
        padding-left: 24px;
        position: relative;
    }

    @keyframes glowing {

        0%,
        100%,
        50% {
            background-color: #fff;
        }

        0%,
        100% {
            box-shadow: 0 0 1px #d5d2d2;
        }

        20% {
            box-shadow: 0 0 6px #d5d2d2;
        }
    }
}

@media only screen and (max-width: 600px) {
    .budget-popup-box .sec-head {
        text-align: center;
        padding-top: 16px;
        padding-bottom: 0;
        display: block;
        font-size: 20px;
    }
}

/* End budget sec*/

.ab-process {
    list-style: none;
    position: relative;
    padding-left: 0;
}

.ab-process li {
    padding-left: 13px;
}

.process_item {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.process__number {
    float: left;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid transparent;
    border: 2px solid transparent;
    box-shadow: 0 2px 4px#0003; 
    text-align: center;
}

.ab-process li .process_body h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.45rem;
    line-height: 26px;
    color: #222;
    margin-bottom: 12px;
    font-family: 'SF UI Text semiBold';
    letter-spacing: -1px;

}

.ab-process li .process_body p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 166.04%;
    color: #222;
    letter-spacing: -0.1px;

}

.active-process__number {
    background-color: #9485fe;
    border-color: #9485fe;
    
}

.ab-process li .active-process__number span {
    color: #fff !important;
}

.ab-process li .process_body {
    width: 85%;
    float: right;
}

.ab-process {
    list-style: none;
    position: relative;
    padding-left: 0;
}

.ab-process li:not(:last-child) {
    margin-bottom: 32px;
}

.ab-process li .process__number span {
    font-size: 15px;
    font-weight: 700;
    color: #484848;
    position: relative;
    top: 3px;
}


.active-step-img {
    width: 100%;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    height: 100%;
    overflow: hidden;
}

.active-step-img img {
    width: auto;
    height: 379px;
    margin: auto;
}

.border-rt {
    border-bottom: 1px solid #d5d5d5;
    width: 850px;
    margin: auto;
}

@media only screen and (max-width: 600px) {

    .ab-process {
        padding: 10px;
    }

    .process__number {
        display: none;
    }

    .ab-process li .process_body {
        width: 100%;
    }

    .ab-process li .process_body h3 {
        margin-bottom: 10px;
        text-align: center;
        font-size: 1.175rem;
        font-weight: normal;
    }

    .ab-process li .process_body p {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }

    .ab-process li:not(:last-child) {
        margin-bottom: 18px;
    }

    .ab-process li {
        padding-left: 0px;
    }

    /*explore by category*/
    .home-px-1 {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px !important;
    }

    .department-section ul {
        grid-template-columns: repeat(auto-fill, minmax(27.33%, 1fr)) !important;
    }

    .d-items {
        padding: 0;
        margin: 0;
        margin-bottom: 0px;
    }

    .dp-img-box {
        justify-content: center;
        align-items: center;
        justify-items: center;
        display: flex;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .d-items span {
        text-align: center;
        margin: inherit;
        margin-left: 0px;
        font-size: 14px;
        width: 100%;
    }

    /*explore by category END*/
}



.total-budget-heading {
    font-size: 15px;
    font-family: 'SF UI Text Bold';
    margin-bottom: 14px;
    padding-left: 8px;
}

.work-budget-card-ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
    gap: 8px !important;
    margin: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 8px;
}

.work-card {
    background: #fff;
    border: 1px solid #ffbf56 !important;
    padding: 12px 0;
    border-radius: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    min-height: 100px;
}

.work-card:hover {
    background-color: rgba(252, 154, 24, 0.0784313725);
}

.work-img {
    width: 40px;
    height: 40px;
    margin: auto;
}

.work-img img {
    max-width: 100%;
    margin: auto;
    max-height: 100%;
}

.work-budget span {
    margin: auto;
    font-size: 13px;
    display: flex;
    justify-content: center;
    font-family: "SF UI Text Bold";
    text-align: center;
}

.work-budget p {
    font-size: 9px;
    display: flex;
    justify-content: center;
}

.n-m-t {
    margin-top: -30px;
}

.work-back {
    top: 21px;
    color: #06c;
}

.url-n-m-t {
    margin-top: 16px;
}

.url-work-ul {
    grid-template-columns: repeat(auto-fill, minmax(91px, 1fr));
}

.url-t-b-h {
    font-size: 18px;
}

.url-w-b {
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    .bug-back-hm {
        top: 0rem !important;
    }

    .work-budget-card-ul {
        gap: 10px !important;
        padding-bottom: 32px;
        padding: 0px;
    }



    .work-img {
        width: 56px;
        height: 56px;
    }

    .work-card:hover {
        background-color: #fff;
    }
  
    .total-budget-heading {
        font-size: 16px;
        font-family: "SF UI Text Semibold";
        padding-left: 0px;
    }

    .work-budget span {
        font-size: 14px;
        font-family: "SF UI Text Semibold";
    }

    .url-bug-back-mob {
        position: relative;
    }

    .url-bug-back-mob a {
        top: 30px !important;
    }

    .work-sec-mob {
        padding: 16px;
    }

    .work-sec-mob::-webkit-scrollbar {
        display: none;
    }

    .bug-back-mob {
        position: relative;
    }

    .bug-back-mob .work-back {
        top: 11px !important;
    }

    .power-head {
        display: flex;
        justify-content: right;
        margin: auto;
        margin-top: 24px;
        font-size: 12px;
        color: #222;
    }

    .area-img {
        margin: 16px auto 11px;
    }

    .area-img img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}

/*Professenol*/
.psl {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(161px, 1fr));
    gap: 8px;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    margin-bottom: 32px;
    margin-top: 0px;
    list-style: none;
}

.plc {
    margin-right: 8px;
}

.plca {
    width: 100%;
    display: block;
    padding: 0;
    max-height: 100%;
    align-items: center;
    background-color: #fff;
    text-align: center;
    border: 1px solid #d5d5d5;
    height: 100%;
    border-radius: 9px;
    box-shadow: 0 1px 3px rgba(36, 39, 44, 0.15);
    overflow: hidden;
}

.plca:hover {
    box-shadow: 0 3px 6px rgba(33, 30, 34, 0.2);
    background-color: #fff;
    border-color: #1a374d;
}

.cover-pic {
    background-size: cover;
    height: 256px;
    position: relative;
    background-position: center;
}

.plca-icon {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    margin: auto;
    border: 3px solid #f2f3ef;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .25);
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -40px;
    background-color: #fff;
}

.plca-icon img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}

.plcat {
    margin-top: 48px;
    padding: 16px 24px 32px;
}

.plcat span {
    font-family: "SF UI Text Medium";
    font-size: 10.5px;
    color: #484848;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    margin-bottom: 6px;
    line-height: 1.25;
    text-transform: uppercase;
}

.plcat h3 {
    font-family: "SF UI Text Medium";
    font-size: 16px;
    color: #24272c !important;
    margin-bottom: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-align: center;
}

.plcat p {
    font-family: 'SF UI Text Regular';
    font-size: 13px;
    color: #24272c;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    line-height: 14px;
}

.plcat span:last-child {
    font-family: "SF UI Text Regular";
    font-size: 14px;
    color: #222;
    display: block;
    text-overflow: ellipsis;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
    text-align: center;
}

.p-address {
    margin-top: 14px;
}

.p-address p {
    font-family: "SF UI Text Regular";
    color: #333;
    font-size: 13.2px;
}

.p-address p span {
    font-family: 'SF UI Text Light';
    color: #333;
}

.plca-all {
    text-align: center;
}

.plca-all a {
    font-family: "SF UI Text Medium";
    font-size: 18px;
}

.carousel-mobile {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    margin: 0;
    padding: 2px;
}

.carousel-mobile::-webkit-scrollbar {
    display: none;
}

.carousel-mobile li {
    width: 156px;
    display: flex;
    float: left;
    list-style: none;
    margin-right: 8px;
    padding: 0;
    position: relative;
    vertical-align: top;
    flex: 0 0 156px;
}

.home-hero-sec-ul .owl-theme .owl-nav.disabled+.owl-dots {
    margin-bottom: -16px !important;
}

/* Budget Section */
.bud-pop-web .p-dialog {
    width: fit-content !important;
    background-color: #fff !important;
}

.bud-pop-web .p-dialog .p-dialog-header {
    display: none;
}

.bud-pop-web .p-dialog .p-dialog-content {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem !important;
    background: url("https://res.cloudinary.com/afterbricks/image/upload/v1699113788/budget-background_a7cyc4.webp");
    background-size: cover;
}

.bud-pop-mob .p-sidebar-bottom {
    height: fit-content !important;
    border-radius: 22px 22px 0px 0px !important;
    overflow: hidden !important;
    background: url("https://res.cloudinary.com/afterbricks/image/upload/v1699113788/budget-background_a7cyc4.webp");

    background-size: cover;
    background-color: #fff;
    background-position: 5px;
    background-repeat: no-repeat;
    background-position-y: 60px;
}

.bud-pop-web .p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.11) !important;
    opacity: 1;
    backdrop-filter: blur(4px);
}