.sec-shadow-web-1 {
    box-shadow: rgb(212 212 212) 0px 0px 8px 2px !important;
}
.home-hero-sec {
    margin: 0 auto  8px;
    max-width: 1600px;
    position: relative;
    padding-top: 8px;
}

.home-hero-sec-ul {
    width: 100%;
    min-width: 100%;
}

.home-hero-sec-ul li {
    width: 100%;
    height: 540px;
    display: flex;
    background-position: center;
    background-size: cover;
}

.home-hero {
    height: 330px;
    width: calc(100% - 6px);
    min-width: calc(100% - 6px);
    margin:auto;
    background-size: cover;
    background-position: 50% !important;
    object-fit: cover;
    background-position: 46% center !important;
    border: 1px solid transparent;
    border-radius: 22px;
    overflow: hidden;
    white-space: nowrap;
    /* box-shadow: rgba(0, 0,0, 0.25) 0px 3px 3px; */
}
.home-hero-sec-ul .owl-theme .owl-nav.disabled+.owl-dots{
    z-index: 999;
    position: relative;
    top: -44px;
}

.inner-banner-sec{
    padding-top: 32px;
    min-height: 224.8px;
}
.inner-banner{
    height: fit-content;
    width: calc(100% - 16px);
    border-radius: 22px;
    overflow: hidden;
    margin:auto;
}
.inner-banner img{
      width: 100%;  
      object-fit: cover;
}
.inner-banner-sec .owl-theme .owl-nav.disabled+.owl-dots{
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
}
@media screen and (max-width: 1200px) {
    .inner-banner-sec {

        min-height: 114.8px;
    }
}
@media only screen and (max-width: 600px){
    .inner-banner-sec{
        margin-top: 0;
        padding-top: 0px;
        min-height: 161.8px;
    }
    .inner-banner{
        border-radius: 16px;
        width: calc(100% - 5px);
    }
    .inner-banner-sec .owl-theme .owl-nav.disabled+.owl-dots{
        bottom: 0px;
    }
    .home-hero-sec-ul .owl-carousel.owl-drag .owl-item {
        width: 100% !important;
    }
    .home-hero-sec-ul .owl-carousel .owl-stage-outer {
        border-radius: 20px;
    }
    .home-hero-sec-ul .owl-carousel .owl-stage {
        display: flex;
        padding: 0px;
    }
    .home-hero-sec-ul .owl-theme .owl-nav.disabled+.owl-dots{
        margin-top: 16px;
        margin-bottom: 0px
    }
    .home-hero-sec {
        margin: 0 auto 0px;
        padding: 14px 14px 10px;
    }

    .home-hero-sec-ul {
        width: 100%;
        margin: 0 auto 4px;
        aspect-ratio: initial;
    }

    .home-hero {
        border-radius: 0px;
        cursor: pointer;
        height: 164px;
        width: 100%;
        overflow: hidden;
    }

    .home-hero img {
        max-width: 100%;
        max-height: 100%;
    }
}