.right-slid-prfl .p-badge.p-badge-info {
  margin-left: 4px;
  font-family: "SF UI Text Light";
  font-size: 9px;
  min-width: 17px;
  height: 19px;
  line-height: 17px;
  font-weight: 700;
  border: 1px solid #bca5d7;
  background: #f6f6f6;
  color: #222;

}

/* globle search */


.globle-search .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #FFECD0;
  border-color: #FC9918;
}

.globle-search .p-inputtext:enabled:hover {
  border-color: #FC9918;
}

.globle-search .p-autocomplete {
  width: 100%;
  min-width: 100%;
}

.globle-search .p-autocomplete .p-inputtext {
  width: 100%;
  border-radius: 24px;
  padding-left: 112px !important;
  border: 1px solid #eceef2;
  padding-right: 39px !important;
  height: 47px;
  background-color: #f7f8fa;
}


.globle-search .p-autocomplete .p-autocomplete-panel {
  max-height: 598px !important;
  border-radius: 28px;
  overflow: hidden;
  padding-bottom: 20px;
  background: #fff;
}

.globle-search .p-autocomplete-panel .p-autocomplete-items {
  max-height: 578px !important;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 24px;
  background: #fff;
}

.globle-search .p-autocomplete-panel .p-autocomplete-items::-webkit-scrollbar {
  width: 2px;
}

.globle-search .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  padding: 3px 32px;
}

.globle-search .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
  font-size: 13px;
}

.globle-search .p-dropdown-panel .p-dropdown-items {
  overflow: hidden;
  border-radius: 8px;
  padding: 0 !important;
}

.globle-search .ui-corner-all {
  border-radius: 10px;
  font-size: 13px;
  font-family: "SF UI Text Medium";
}

::placeholder {
  color: #495057;
  font-family: 'SF UI Text Regular';
  opacity: 0.3;
  font-size: 12px;
}

/*Quick View Desgin*/

.quick-view .p-dialog {
  min-width: 800px;
  width: 800px !important;
  max-width: 94vw !important;
  display: -ms-grid;
  display: grid;
  max-height: 85vh;
  min-height: 410px;
  transform: none;
  opacity: 1;
  padding: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
  background-color: #fff;
  border-radius: 22px !important;
}

.quick-view .p-dialog .p-dialog-content:last-of-type {
  border-radius: 22px !important;
}

.quick-view .p-dialog-mask {
  z-index: 100002 !important;
}

.quick-view .p-dialog-header {
  display: none;
  padding: 0 !important;
}

.bud-pop-web .p-component-overlay {
  background-color: #000000cc !important;
}


.quick-view .p-dialog-header-icon {
  display: flex !important;
  position: absolute !important;
  right: 0;
  top: 0;
  transition: .5s;
  cursor: pointer;
  z-index: 99;
  padding: 4px;
  border-radius: 20px !important;
}

.quick-view .p-dialog-header-icon:enabled:hover {
  color: #222 !important;
  border-color: transparent !important;
  background: #fff !important;
}

.quick-view .p-dialog-content {
  position: relative;
  height: auto;
  color: #222 !important;
  padding: 32px !important;
}

.quick-view .pi {
  font-size: 10px !important;
  color: #222;
  border: 1px solid #222;
  border-radius: 50%;
  padding: 2px;
  font-weight: 700;
}

.quick-view .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border: 1px solid #fff !important;
  background-color: #fff !important;
  color: #222 !important;
  text-decoration: none;
  padding: 0px !important;
  font-weight: 400 !important;
  display: block !important;
}

.quick-view .p-accordion-header-link .p-accordion-toggle-icon {
  border: none !important;
  position: absolute;
  right: 0;
  top: 4px;
  font-size: 14px !important;
  margin-right: 0 !important;
}

.quick-view .p-accordion-content {
  border: none !important;
  padding: 6px 0 !important;
  border-radius: 22px !important;
}

.quick-view .p-accordion-header-text {
  font-family: 'SF UI Text Regular';
  font-weight: normal;
  color: #222;
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}

.quick-view .p-accordion .p-accordion-header a {
  border: none;
  border-top: 1px solid #fff;
  background-color: #fff;
  padding: 0.571em 0 !important;
}

.quick-view .p-accordion .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover a {
  border: transparent;
  background-color: #fff;
}

/*Quick View Desgin*/
/*Rewards Rates & Terms*/
.cbk-detail .p-sidebar-top {
  margin: auto;
  left: 0;
  right: 0;
  height: fit-content !important;
  max-height: 90vh !important;
  padding: 32px;
}

/*END Rewards Rates & Terms*/
@media only screen and (max-width: 620px) {
  .globle-search .p-autocomplete .p-autocomplete-input {
    padding-left: 108px !important;
    width: 100%;
    border-radius: 50px;
    padding-left: 107px !important;
    border: 1px solid #c8c6c6;
    padding-right: 49px !important;
    height: 40px;
    /* height: 42.19px; */
    margin-top: -1px;
    margin-left: -1.5px;
  }

  .globle-search .p-autocomplete .p-autocomplete-panel {
    max-height: 434px !important;
    box-shadow: 0 1px 3px #0000004d;
  }
  .only-tablet {
    display: none;
  }

 
}

/* endgloble search */
.budget-form p-inputnumber,
.p-inputnumber {
  width: 100% !important;
}

.budget-form .p-inputnumber-input {
  background-color: #fff;
  color: #484848;
  border-right: 1px solid #ededed;
  border-radius: 4px;

  width: 100%;
  border: 1px solid #ccc;
  padding: .7rem .8rem .8rem 1.6rem !important;
}

/* Sidebar */
.p-sidebar .p-sidebar-header {
  display: none;
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
  padding: 0;
}

.megamenu-slider-left .p-sidebar-left {
  width: 100%;
}

/* End Sidebar */


/* Slider Botton on mobile */
.slider-mobile .p-sidebar-bottom {
  height: fit-content;
}

/* Slider Botton on mobile */

/*input box*/
.p-inputnumber,
.p-inputnumber {
  width: 100%;
}

/* end input box*/

.add-product-spaces-popoup .p-sidebar {
  height: fit-content;
  border-radius: 16px 18px 0px 0px;
  max-height: 90vh;
}

.add-product-spaces-popoup .p-dialog-mask {
  z-index: 100002 !important;
}

.add-product-spaces-popoup .p-sidebar-content {
  overflow: hidden !important;
}

.add-product-spaces-popoup .p-checkbox {
  display: none;
}

.add-product-spaces-popoup .p-dialog .p-dialog-content {
  padding: 0 0rem 0rem 0rem;
  border-radius: 6px !important;
}

.new-login-slider .p-sidebar-bottom {
  height: fit-content;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  padding: 24px;
  z-index: 11001000000 !important;
  background: url("https://res.cloudinary.com/afterbricks/image/upload/v1699113788/budget-background_a7cyc4.webp");
  background-size: cover;
  background-color: #fff;
}

.mobabauth-pops .ui-sidebar-left.ui-sidebar-active {
  z-index: 91002000000 !important;
}

.newworkcreatedsearch .p-dialog .p-dialog-header {
  padding: 3px 3px 0px !important;
}

.newworkcreatedsearch .p-dialog-content {
  padding: 0 !important;
  background: #fff;
  border-radius: 16px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
}

.newworkcreatedsearch .p-dialog-header-icon:last-child {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.newworkcreatedsearch .pi {
  font-size: 1.2rem;
}

.newworkcreatedsearch .p-dialog-header {
  background: none !important;
}

.core-option-slider .p-sidebar-bottom {
  padding: 0;
  border: none;
  box-shadow: none;
  height: fit-content !important;
  border-radius: 24px 24px 0rem 0rem !important;
}

.core-option-slider .p-sidebar-footer {
  display: none;
}

/* p-tooltip */
.p-tooltip .p-tooltip-text {
  background-color: black !important;
  color: #fff !important;
  font-size: 11px;
  padding: 24px 0px 0px 0px;
  height: 30px;
  line-height: 8px;
  font-family: "SF UI Text Light";
}

.p-tooltip .p-tooltip-arrow {
  display: none;
}

/* end p-tooltip*/

/*oem registration*/
.input-error-category .p-autocomplete {
  display: block !important;
  text-align: left !important;
}

.input-error-category .p-autocomplete .p-inputtext {
  width: 100% !important;
  height: 40px !important;
  padding: 0 28px !important;
  font-size: 0.9rem;
}

.input-error-category .p-autocomplete .p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.input-error-category .p-dropdown {
  width: 100% !important;
  border-radius: 3px;
  text-align: right;
}

.input-error-category .p-multiselect {
  width: 100% !important;
  border-radius: 3px;
}

.input-error-category .p-inputtext {
  height: auto !important;
  padding: 0 0px !important;
  font-size: 0.9rem;
}

.input-error-category .p-dropdown .p-dropdown-trigger {
  width: 2rem;
}

.input-error-category .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  font-size: 12px;
}

.input-error-category .p-dropdown-panel .p-dropdown-items {
  padding: 0 0 0 36px;
  text-align: left;
}

.input-error-category .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #fff;
  background-color: #007ad9;
}

.input-error-category .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0;
  color: #333;
  background-color: transparent;
  border-radius: 0;
  font-size: 0.9rem;
}

.input-error-category .p-multiselect .p-multiselect-trigger {
  width: 2rem;
}

.input-error-category .p-multiselect .p-multiselect-trigger-icon {
  font-size: 12px;
}

.input-error-category .p-multiselect .p-multiselect-label {
  padding: 0.429em 2em 0.429em 0.429em;
  font-size: 0.9rem !important;
  color: #333 !important;
}

.input-error-category .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0;
  color: #333;
  background-color: transparent;
  border-radius: 0;
}

.input-error-category .p-multiselect .p-checkbox {
  margin: 0 0.5em 0 0;
  width: 20px !important;
  height: 20px !important;
}

.input-error-category .p-multiselect .p-checkbox .p-checkbox-box {
  border: 1px solid #a6a6a6;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 3px;
}

.input-error-category .p-multiselect .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #fff;
}

.input-error-category .p-multiselect .p-multiselect-item.p-highlight {
  color: #fff !important;
  background-color: #007ad9 !important;
}

.input-error-category .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

.input-error-category .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.input-error-category .p-multiselect .p-multiselect-panel .p-multiselect-items {
  padding: 0.2em 0;
}

.input-error-category .p-multiselect .p-multiselect-item:focus {
  box-shadow: none !important;
}

.input-error-category .p-checkbox-box.p-highlight:hover {
  border-color: #005b9f !important;
  background-color: #005b9f !important;
  color: #fff;
}

.input-error-category .p-multiselect {
  text-align: left;
}

/*oem registration*/
.p-sidebar-footer {
  display: none;
}

/* start save-idea-department & space dropdown*/

.new-drop-down .p-dropdown {
  border: 1px solid #f2f2f2 !important;
  width: 100% !important;
  box-shadow: none;
  border-radius: 3px;
}

.new-drop-down {
  width: 200px !important;
  margin-right: 8px;
}

.new-drop-down .p-dropdown-trigger {
  width: 2em !important;
  color: #848484;
  border-left: 1px solid #f0eeff;
  background: #f0eeff!important;
  border-radius: 0px !important;
}

.new-drop-down .pi {
  font-size: 14px;
}

.new-drop-down .p-inputtext {
  font-family: "SF UI Text Medium";
  font-size: 13px;
  line-height: 17px;
  color: #222 !important;
  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  padding: 9px 22px !important;
  border:  1px solid #f0eeff !important;
  border-radius: 2px 0px 0px 2px !important;
}

.new-drop-down .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

.new-drop-down body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item,
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group {
  margin: 0;
  padding: 0.429em 0.857em;
}

.new-drop-down .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #333333;
  background-color: #eaeaea;
}

.new-drop-down .p-dropdown-item {
  padding: 0.429em 0.857em !important;
  color: #222;
  background-color: transparent;
  border-radius: 0;
  font-family: 'SF UI Text Medium';
  font-size: 13px;
  padding-left: 36px !important;
}

.new-drop-down .p-dropdown-item-group {
  padding: 8px 0.857em !important;
}

.new-drop-down .card-grop-drop {
  font-size: 13px;
  font-family: 'SF UI Text Bold';
  display: block;
  color: #000;
}

.new-drop-down .p-dropdown-items-wrapper {
  max-height: 360px !important;
}

.new-drop-down .p-dropdown-items {
  padding: 0 !important;
  border-radius: 3px;
}

.p-button:focus {
  box-shadow: none;
}

/* live offers confirm popup starts*/
.live-offers-confirm-popup .p-dialog-header {
  display: flex;
  justify-content: center;
  font-family: "SF UI Text Semibold";
  font-size: 18px;
  line-height: 140%;
  color: #222;
  padding-top: 8px;
}

.live-offers-confirm-popup .p-dialog {
  position: absolute;
  width: 318px;
  height: 222px;
  overflow: hidden;
  background: #fff;
  padding: 32px;
  top: 60px !important;
  border-radius: 16px;
  z-index: 99999999 !important;
}

.live-offers-confirm-popup .p-dialog .p-dialog-content {
  border: none;
  display: flex;
  padding: 0;
  flex-grow: 0;
}

.live-offers-confirm-popup .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  font-family: "SF UI Text Regular";
  font-size: 14px;
  line-height: 140%;
  color: #222;
  margin-left: 0px;
  margin: auto;
  text-align: center;
}

.live-offers-confirm-popup .p-dialog .p-dialog-footer {
  border: none;
  padding: 0;
  position: absolute;
  bottom: 24px;
  right: 18px;
}

.live-offers-confirm-popup .p-dialog .p-footer {
  position: absolute;
  display: flex;
  right: 55px;
  bottom: 0;
}

.live-offers-confirm-popup .p-footer button {
  height: 41px;
  border-radius: 8px !important;
  border: 1px solid #222;
  font-size: 14px;
  font-family: 'SF UI Text Regular';
  zoom: 1;
  color: #fff !important;
  padding: 10px 27px !important;
}

.live-offers-confirm-popup .p-footer button:first-child {
  background: #f2f2f2;
  color: #222 !important;
  border: 1px solid #cfd8dc;
  border-radius: 26px !important;
}

.live-offers-confirm-popup .p-footer button:last-child {
  background: #FFcf80 !important;
  border: 1px solid #ffbf56 !important;
  color: #222 !important;
  border-radius: 26px !important;
}

.cm-dialog-1 .p-dialog{
  width: 23vw;
  border-radius: 22px;
  overflow: hidden;
}
.cm-dialog-1 .p-dialog .p-dialog-footer {
  justify-content: end;
  display: flex;
}

.cm-dialog-1 .p-dialog .p-dialog-header{
  justify-content: center;
}
.cm-dialog-1 .p-dialog .p-footer {
  display: flex;
}

.cm-dialog-1 .p-footer button {
  height: 41px;
  border-radius: 8px !important;
  border: 1px solid #222;
  font-size: 14px;
  font-family: 'SF UI Text Regular';
  zoom: 1;
  color: #fff !important;
  padding: 10px 27px !important;
}

.cm-dialog-1 .p-footer button:first-child {
  background: #f2f2f2;
  color: #222 !important;
  border: 1px solid #cfd8dc;
  border-radius: 26px !important;
}

.cm-dialog-1 .p-footer button:last-child {
  background: #FFcf80 !important;
  border: 1px solid #ffbf56 !important;
  color: #222 !important;
  border-radius: 26px !important;
}
/*END live offers*/
.pac-container {
  position: fixed !important;
}
.sample-switch-box .p-hidden-accessible{
  display: none;
}
.sample-mob .p-hidden-accessible{
  display: none;
}
@media screen and (max-width:600px) {
  .globle-search .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    padding: 5px 16px !important;
  }

  /*live offers confirm popup mobile starts*/
  .budget-form .p-inputnumber-input {
    font-family: "SF UI Text Medium" !important;
  }

  .new-drop-down .p-dropdown-items-wrapper {
    border: 1px solid #afadad;
  }

  .live-offers-confirm-popup .p-dialog {
    width: 100%;
    height: fit-content;
    padding-bottom: 90px !important;
    right: 0;
    left: 0 !important;
    margin: auto;
    justify-items: center;
    border-radius: 16px 16px 0 0;
    padding: 24px;
    bottom: 0;
    top: auto !important;
  }
  .new-confirm-dialog .p-dialog{
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }

  .live-offers-confirm-popup .p-dialog .p-dialog-content {
    display: flex;
    padding: 0;
    align-items: center;
  }

  .live-offers-confirm-popup .p-dialog .p-footer {
    right: 21px;
    bottom: -7px;
  }

  .live-offers-confirm-popup .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    font-size: 16px;
  
  }

  .live-offers-confirm-popup .p-footer button {
    padding: 8px 55px !important;
  }
  
  .cm-dialog-1 .p-dialog{
    width:calc(100% - 32px);
   
  }

  /*END live offers confirm popup mobile*/
  .pac-container {
    z-index: 9999 !important;
    position: absolute !important;
  }

  .pro-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: #222 !important;
    font-size: 13px;
    font-family: "SF UI Text Medium" !important;
  }

  .new-drop-down .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    text-align: left;
    padding: 0.429em 0.857em !important;
  }

  .new-drop-down .p-dropdown {
    border: 1px solid #f0dbff !important;
    border-radius: 1px !important;
  }

  .product-detail-container .p-galleria .p-galleria-item-nav,
  .product-detail-container .p-galleria .p-galleria-close,
  .project-images-section .p-galleria .p-galleria-item-nav,
  .project-images-section .p-galleria .p-galleria-close {
    margin: 0.5rem !important;
  }

  .space-pop-2 .p-sidebar-content {
    height: 100%;
  }

  .new-drop-down .p-dropdown-label.p-placeholder {
    font-size: 14px !important;
    padding: 9px 22px !important;
  }

  .new-drop-down {
    width: calc(50% - 4px) !important;
    margin-bottom: 0px;
    height: 42px;
    padding: 0px;
  }

  .new-drop-down .p-dropdown-item.p-highlight {
    color: #fff;
    background: #1a374d;
  }

  .new-drop-down .p-ripple {
    padding-left: 12px;
  }

  .new-drop-down .new-drop-down .p-inputtext {
    padding: 8px !important;
  }

  .new-drop-down .p-inputtext {
    text-overflow: unset;
    font-size: 13px !important;
    padding: 9px 16px !important;
    /*white-space: unset;*/
  }

  .new-drop-down .p-dropdown-items-wrapper {
    max-height: 320px !important;
  }

  /* .new-drop-down .p-dropdown .p-dropdown-trigger {
    background: #f2f2f2 !important;
  } */

  /*Global Search Bar*/
 

  .globle-search .p-dropdown-panel .p-dropdown-items {
    font-size: 13px !important;
    font-family: "SF UI Text Regular" !important;
    text-align: center !important;
    padding: 0 !important;
  }

  .globle-search .p-dropdown-items .p-dropdown-item.p-highlight {
    padding: 0.429em 0.857em !important;
    color: #fff !important;
    background: #1a374d !important;
  }

  .globle-search .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.429em 0.857em !important;
    border: 0;
    color: #333;
    background-color: transparent;
    border-radius: 0;
  }

  .globle-search .p-overlay {
    right: 0 !important;
    top: 2.5rem !important;
    min-width: 293px !important;
  }

  /*Global Search Bar*/
  .new-drop-down .p-dropdown-trigger {
    width: 2rem !important;
  }

  .new-drop-down .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #fff !important;
    background: #1a374d !important;
  }

  .p-dropdown-items {
    padding: 0 !important;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #222 !important;
  font-size: 13px;
  font-family: "SF UI Text Regular";
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #222;
  font-size: 13px;
  background: #fff;
  font-family: "SF UI Text Regular";
}

/* End save-idea-department & space dropdown*/

/*galleria css*/
.p-galleria-mask {
  --maskbg: unset !important;
}

.p-galleria-mask {
  background-color: rgba(255, 255, 255, 0.96) !important;
}

.p-galleria .p-galleria-item-prev {
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.5);
  left: 50px;
}

.p-galleria .p-galleria-item-next {
  box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.5);
  right: 50px;
}

.p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
  width: 1.3rem !important;
  height: 1.3rem !important;
  color: #bdb8b8;
}

.p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: #fff !important;
  color: #bdb8b8 !important;
}

.project-images-section .p-galleria-mask {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.product-detail-container .p-galleria .p-galleria-close,
.project-images-section .p-galleria .p-galleria-close {
  background: #fff !important;
  color: #bdb8b8 !important;
  margin: 1rem;
  width: 3rem;
  height: 3rem;
  border: 1px solid #bdb8b8;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  right: 50px;
}

.product-detail-container .p-galleria .p-galleria-item-nav,
.project-images-section .p-galleria .p-galleria-item-nav {
  background: #fff !important;
  color: #bdb8b8;
  margin: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid #bdb8b8;
}

.product-detail-container .p-galleria .p-galleria-close .p-galleria-close-icon,
.product-detail-container .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon .product-detail-container .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.project-images-section .p-galleria .p-galleria-close .p-galleria-close-icon,
.project-images-section .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon .project-images-section .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon {
  font-size: 1.5rem;
}

.product-detail-container .p-link:focus,
.project-images-section .p-link:focus {
  background: #fff !important;
  border-radius: 50% !important;
  border: 1px solid #bdb8b8 !important;
}

/*End galleria css*/


.newborderALignment .p-dropdown {
  border: none;
}

.quantityDropDown .ui-dropdown {
  border-color: #d0d0d0 !important;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 1px solid #cbc9c9;
}

.newborderALignment .p-dropdown-label {
  padding: 0 12px;
  text-align: right;
}

.newborderALignment .p-dropdown-trigger {
  width: 1.5em !important;
  background-color: #fff !important;
}

.medp2i1-formm .quantityDropDown .ui-dropdown .ui-dropdown-trigger .p-icon {
  color: #9c9c9c;
  height: 11px;
}

.bud-pop-web .p-dialog {
  border-radius: 20px !important;
}

.bud-pop-web .p-dialog .p-dialog-header {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  overflow: hidden !important;
}

.bud-pop-web .p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  overflow: hidden !important;
}

@media only screen and (max-width: 600px) {
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-family: "SF UI Text Medium";
  }

  .ab-project-model .p-sidebar-bottom {
    padding: 2em 1rem;
    max-height: 90vh;
    height: fit-content;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  .project-body {
    width: 100%;
  }

  .ab-p-project-list {
    padding: 0 4px;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }

  .ab-p-project-card span {
    margin-bottom: 4px;
  }

  .bud-pop-mob .p-component-overlay {
    background-color: #0006 !important;
  }

  .budget-section .p-inputtext,
  .bud-pop-mob .p-inputtext {
    border: 1px solid #06c !important;
  }

  .budget-section .p-element,
  .bud-pop-mob .p-element {
    width: 100% !important;
    text-align: center;
  }

  .project-images-section .p-galleria .p-galleria-close {
    width: 1.5rem !important;
    height: 1.5rem !important;
    right: 13px;
    top: 17px;
  }

  .p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
    width: 0.85rem !important;
    height: 0.85rem !important;
  }

  .product-detail-container .p-galleria .p-galleria-item-nav,
  .project-images-section .p-galleria .p-galleria-item-nav {
    width: 2rem;
    height: 2rem;
  }

  .p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
    width: 1rem !important;
    height: 1rem !important;
  }

  .product-detail-container .p-galleria .p-galleria-close {
    width: 1.5rem !important;
    height: 1.5rem !important;
    right: 13px;
    top: 17px;
  }

  .product-detail-container .p-galleria .p-galleria-item-next,
  .project-images-section .p-galleria .p-galleria-item-next {
    right: 13px;
  }

  .product-detail-container .p-galleria .p-galleria-item-prev,
  .project-images-section .p-galleria .p-galleria-item-prev {
    left: 13px;
  }

  .p-inputtext::placeholder {
    font-size: 14px;
    font-family: "SF UI Text Regular";
    color: #222;
  }
}

.mob-workpage .p-sidebar-bottom {
  max-height: 90vh;
  height: fit-content;
  border-radius: 22px 22px 0px 0px;
}

.mob-workpage .p-sidebar .p-sidebar-header+.p-sidebar-content {
  border-radius: 22px 22px 0px 0px;
}

.popup-window-header-item .p-dropdown {
  border-radius: 0;
}

.popup-window-header-item .p-inputtext {
  padding: 3px 0px;
}

.popup-window-header-item .p-dropdown-trigger {
  width: 1.5em !important;
  background-color: #fff !important;
}

.p-dialog-content {
  overflow: hidden;
}

/*basic info popup*/
.basic-info-popup .p-sidebar-bottom {
  max-height: 86vh;
  height: fit-content;
  background-size: contain;
  background-position: center;
  padding: 24px;
  padding-bottom: 82px;
  border-radius: 22px 22px 0px 0px;
  background-color: #fff;
}

.basic-info-popup .p-dialog {
  border-radius: 20px !important;
  /* padding: 48px; */
  border-radius: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}

.basic-info-popup .p-dialog .p-dialog-content {
  background-color: transparent;
  padding: 0;
  border-radius: 0 !important;
  padding: 48px;
}

.basic-info-popup .p-dialog .p-dialog-content::-webkit-scrollbar {
  display: none;
}

.add-product-spaces-popoup .p-dialog .p-dialog-content::-webkit-scrollbar {
  display: none;
}

.basic-info-popup .p-dialog-title {
  font-size: 24px;
  color: #222;
  font-family: "SF UI Text Bold" !important;
  font-weight: 400 !important;
}

.basic-info-popup .sub-title {
  font-size: 16px;
  color: #222;
  font-family: "SF UI Text Regular" !important;
  padding-top: 0px;
  text-align: center;
}

.basic-info-popup .p-dialog-footer {
  background: transparent !important;
}

@media only screen and (max-width: 620px) {
  .basic-info-popup .p-sidebar-bottom {
    padding: 24px 16px 32px !important;
  }

  .basic-info-popup .p-dialog {
    padding: 24px 16px 65px !important;
    position: absolute;
    bottom: 0;
    border-radius: 20px 20px 0px 0px !important;
    width: 100% !important;
  }
  .basic-info-popup .p-dialog .p-dialog-content {
    padding: 0px;
  }

  .basic-info-popup .p-dialog .p-dialog-footer {
    padding: 8px;
  }
}

/*  End basic info popup*/

.cb-autocomplete .p-inputtext {
  width: 100% !important;
  text-align: center;
}

.cb-autocomplete .p-autocomplete {
  width: 100% !important;
}

.cb-autocomplete .p-inputtext::placeholder {
  text-align: center;
  font-size: 16px;
}

.globle-search .p-autocomplete-panel::-webkit-scrollbar {
  display: none;
}

.globle-search .p-autocomplete-loader {
  display: none;
}

.share-popup .p-dialog {
  max-width: 450px;
  border-radius: 24px;
  overflow: hidden;
  font-family: "Roboto", Arial, sans-serif;
  box-shadow: none;
}

.share-popup .p-dialog .p-dialog-header {
  padding: 60px 24px 24px 24px;
}

.share-popup .p-dialog .p-dialog-header span {
  font-size: 1.25rem;
  font-weight: 500;
}

.share-popup .p-dialog .p-dialog-content {
  padding: 0px 0px 0px 0px;

}

.connect-popup .p-dialog {
  max-height: 98%;
}

.connect-popup .p-dialog .p-dialog-content {
  padding: 0rem;
  background-color: #f7f8fa;
}

.connect-popup .p-dialog .p-dialog-content {
  color: #0a0a0a;
}

@media only screen and (max-width: 620px) {
  .share-popup .p-dialog {
    max-width: calc(100% - 32px);
    margin: auto;
  }
}
.new-confirm-dialog{

}
.new-confirm-dialog .p-button:focus{
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #FFE082, 0 1px 2px 0 black;
}
.new-confirm-dialog .p-button{
  background: #f7a630;
  border: 1px solid #f7a630;
  padding: 0.4rem 1rem;
}
.new-confirm-dialog .p-button:enabled:hover {
  background: #FF5722;
  color: #ffffff;
  border-color: #FF5722;
}
.new-confirm-dialog .p-button.p-button-outlined{
  background: transparent;
color: #f7a630;
}
.new-confirm-dialog  .p-button.p-button-outlined:enabled:hover {
  background: rgba(59, 130, 246, 0.04);
  color: #FF5722;
  border: 1px solid;
}

.sub-work-container .p-sidebar-bottom{
  height: fit-content;
  max-height: 100vh;
}

::ng-deep .p-sidebar.p-sidebar-bottom {
  transform: translateY(100%) !important;
  transition: transform 1.3s ease-in-out !important;
}

::ng-deep .p-sidebar.p-sidebar-bottom.p-sidebar-active {
  transform: translateY(0) !important;
}

.new-confirm-dialog .p-dialog.p-confirm-dialog .p-confirm-dialog-icon{
display: none;
}