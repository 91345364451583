.address-popup{
 .p-dialog{
    width: 30vw !important;
    border-radius: 9px;
    overflow: hidden;
}
 .p-dialog .p-dialog-header{
display: flex !important;
}

.form-check-input{
    margin-left: -1.3em !important;
}
.form-check-label{
    padding-left: 25px !important;
    display: block;
}
.p-autocomplete{
    width: 100%;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item{
    text-align: left;
}
    .p-inputtext {
        width: 100%;
        border: 1px solid #ebebeb;
        font-size: 16px;
        height: calc(2.25rem + 2px);
        background-color: #f2f2f2;
      
    }
    .p-inputtext:enabled:hover {
        border-color: #222;
    }
    .p-button-label{
        font-weight: inherit;
    }
   .p-dialog-footer button{
    border-radius: 26px;
    padding: 0.5rem 1.1rem;
    margin-right: 0px !important;
    border: 1px solid #c8c6c6;
    color: #222 !important;
    border-radius: 26px;
    font-family: "SF UI Text Semibold";
    font-size: 14px;
    line-height: 17px;
    border-radius: 26px;
    height: 41px;
    }
    .p-sv-button {  
        border: 1px solid #ffbf56 !important;
        background-color: #ffcf80 ;
        color: #222 !important;
        font-family: "SF UI Text Regular";
        position: relative;
        text-transform: capitalize !important;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .8;
   
    }
    .p-button:enabled:hover {
        background-color: #ffbf56;
        border-color: #ffbf56;
        color: #222;
        opacity: .8;
    }
    .p-button:focus {
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #fbe19d, 0 1px 2px 0 black;
    }
.errs{
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: left;
}
.form-group{
    text-align: left;
}
.p-dialog .p-dialog-footer{
    padding: 1rem 1.5rem 1rem 1.5rem !important;
}
@media screen and (max-width: 768px) {
    .p-dialog{
        width: 97vw !important;
        max-height: 97vh !important;
    }
    .text-xl {
        font-size: 1rem !important;
    }
    .p-dialog .p-dialog-header{
        padding: 1rem;
    }
    .p-dialog .p-dialog-content{
        padding: 0 1rem 2rem 1rem;
    }
    .p-dialog .p-dialog-footer{
        padding: 1rem;
    }
    .c-details p{
        font-size: 14px !important;
    }
    .form-control{
     line-height: 1;   
     font-size: .851rem;
    }
    .p-dialog .p-dialog-footer button{
        font-size: .85rem;
        padding: 0.6rem 1rem;
        line-height: 1;
        font-family: 'SF UI Text Medium';
    }
}
}