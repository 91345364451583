.banner-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .08) !important;
    margin-top: 24px;
    border-radius: 22px;
    margin-bottom: 24px;
    position: relative;
    aspect-ratio: 4/1;
}
.showroom-name-banner-text{
    font-size: 3rem;
    line-height: 1.1;
    letter-spacing: 0.2px;
    font-weight: 700;
    text-shadow: 3px 7px 10px rgba(0, 0, 0, 0.2);
    color: #343a40;
    font-family: "Inter var";
    width: 90% !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: fit-content;
    margin: auto !important;
    padding-bottom: 12px;
}
.banner-container img{
    max-width: 100%;
    object-fit: contain;
    margin: auto;
    max-height: 100%;
    border-radius: 22px;
}
.banner-container-loader{
    aspect-ratio: 139/30;
    width: 100%;
    margin-top: 24px;
    border-radius: 22px;
    margin-bottom: 24px;
    background-color: #e9ecef7a;
}
.brand-banner-heading{
    position: absolute;
    text-align: center;
    z-index: 8;
    width: fit-content;
    height: fit-content;
    margin: auto;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
@media only screen and (max-width: 600px) {
    .banner-container {
        height: 180px;
        margin-top: 16px;
        border-radius: 22px;
        margin-bottom: 16px;
        aspect-ratio: 71/36;
    }
    .showroom-name-banner-text{
        font-size: 1.5rem;
    }
    .banner-container img{
        object-fit: cover;
        height: 180px;
        max-height: inherit;
        border-radius: 9px;
    }
    .px-sm-16{
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    .banner-container-loader{
        height: 180px;
        border-radius: 9px;
    }
}