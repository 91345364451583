.populer-product-container .owl-theme .owl-nav {
    margin-top: 0px;
    /*position: absolute;*/
    top: - 0px;
    right: 0;
    display: flex;
    /*visibility: hidden;*/
}

.populer-product-container .owl-theme .owl-nav [class*=owl-] {
    color: #000 !important;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    height: 45px;
    width: 46px;
    background-color: #fff !important;
    border: 0.5px solid #00000017;
    cursor: pointer;
    border-radius: 20% !important;
    display: flex;
}

.populer-product-container .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 40%;
    left: -27px;
    border-radius: 50% !important;
    border: 1px solid #1A374D;
    transform: translateY(-50%);
}

.populer-product-container .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 40%;
    right: -20px;
    border-radius: 50% !important;
    border: 1px solid #1A374D;
    transform: translateY(-50%);
}

.populer-product-container .owl-theme .owl-nav i {
    position: relative;
    top: 1px;
    right: 10px;
    left: 2px;
    bottom: 0;
    font-size: 1.5rem;
    color: #1A374D !important;
    margin: auto;
}

.populer-product-container .owl-prev,
.populer-product-container .owl-next {
    visibility: hidden;
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    z-index: 99;
    background-color: #fff;
    border-color: #1a374d;
    border: 1px solid #1A374D !important;
}

.populer-product-container:hover .owl-prev,
.populer-product-container:hover .owl-next {
    visibility: visible;
}

/*compare page css*/


.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #FFF;
    visibility: visible;
    text-decoration: none;
}

.compare-carousel-class .owl-next,
.owl-prev {
    visibility: hidden;
}

.compare-carousel-class:hover .owl-next,
.owl-prev {
    visibility: visible;
}

/*.compare-carousel-class .owl-carousel .owl-nav.disabled {
    display: block;
}*/
.compare-carousel-class .owl-prev,
.compare-carousel-class .owl-next {
    visibility: hidden;
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    z-index: 99;
    background-color: #fff;
    border-color: #1a374d;
    border: 1px solid #1A374D !important;
}



.compare-carousel-class .owl-theme .owl-nav .owl-next {
    top: 119px;
    right: 0;
    border-radius: 0 !important;
    color: #fff !important;
    box-shadow: none;
    width: 40px;
    height: 40px;
    font-size: 0px;
    padding: 6px;
    border: 0.5px solid #00000017 !important;
    border-radius: 50% !important;
    border-color: #1a374d !important;

}

.compare-carousel-class .owl-theme .owl-nav .owl-prev {
    left: 0;
    width: 40px;
    height: 40px;
    top: 119px;
    border-radius: 0 !important;
    color: #fff !important;
    box-shadow: none;
    font-size: 0px;
    padding: 6px;
    border-radius: 50% !important;
    border: 0.5px solid #00000017 !important;
    border-color: #1a374d !important;
}



.brand-section .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 30%;
    right: -27px;
    border-radius: 50% !important;
    border: 1px solid #1A374D;
}

.brand-section .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 30%;
    left: -27px;
    border-radius: 50% !important;
    border: 1px solid #1A374D;
}

.brand-section .owl-theme .owl-nav i {
    position: relative;
    top: 1px;
    right: 10px;
    left: 2px;
    bottom: 0;
    font-size: 1.5rem;
    color: #1A374D !important;
    margin: auto;
}
.hero-section .heroSlide-3{
    margin: 24px;
    height:333px !important;
    background: #00bcd40f !important;
    margin-top: 32px !important;
    border-radius: 16px;
    padding: 45px 24px 24px 24px;
}
.product-collection .owl-carousel.owl-drag .owl-item{
    width: 100px !important;
    margin-right: 16px;
}
/*.brand-section .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 30%;
    right: 0;
    border-radius: 50% !important;
    border: 1px solid #1A374D;
}*/
/*.brand-section .owl-theme .owl-nav .owl-prev{
    height: 40px;
    width: 48px;
    color: #000!important;
    background-color: #fff!important;
    border-radius: 20%!important;
    border: 0.5px solid #00000017!important;
    display: inline-grid;
    display: inline-grid;
   
}
.brand-section .owl-theme .owl-nav .owl-next{
    height: 40px;
    width: 48px;
    color: #000!important;
    background-color: #fff!important;
    border-radius: 20%!important;
    border: 0.5px solid #00000017!important;
    display: inline-grid;
    display: inline-grid;
}*/


@media screen and (max-width:600px) {
    .owl-theme .owl-dots .owl-dot{
        display: none !important;
    }
    .product-collection .owl-carousel .owl-stage{
        width: max-content !important;
    }

    .populer-product-container .owl-theme .owl-nav .owl-next,
    .populer-product-container .owl-theme .owl-nav .owl-prev {
        top: 8rem;
        height: 30px;
        width: 30px;
        right: 0.5rem;
        display: flex;
        justify-content: center;
        border: 1px solid #1A374D;
        align-items: center;
    }

    .populer-product-container .owl-theme .owl-nav .owl-prev {
        left: 0;
    }

    /*.populer-product-container .owl-theme .owl-nav .owl-prev{
    top: 35px;
    height: 30px;
    width: 30px;
    left: 0;
    display: flex;
    justify-content: center;
    border: 1px solid #1A374D;
    align-items: center; 
}*/
    .populer-product-container .owl-theme .owl-nav i {
        font-size: 14px;
        text-align: center;
        display: contents;
    }

    .compare-carousel-class .owl-theme .owl-nav .owl-next {
        top: 75px;
        right: -6px;
        border-color: #1a374d !important;
        height: 28px;
        width: 28px;
        border-radius: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .compare-carousel-class .owl-theme .owl-nav .owl-prev {
        top: 75px;
        left: -6px;
        border-color: #1a374d !important;
        height: 28px;
        width: 28px;
        border-radius: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .owl-theme .owl-nav [class*=owl-] {
        visibility: hidden ;
    }
    .most-viewed-carusel .owl-carousel.owl-drag .owl-item{
        width: 162.5px !important;
    }
    .category-brand-carusel .owl-carousel.owl-drag .owl-item{
        width: 116.5px !important;
    }

}