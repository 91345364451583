/*============== Text ===============*/ 
.text-shodow-2{
    text-shadow: 0 0 15px rgba(0, 0, 0, .8);
}
.text-900{
color: #222 !important;
}
/*============== End text=============*/ 
/*============ three col ==============*/
.ab-row-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:8px;
    padding: 0;
    align-items: center;
    list-style: none;
}

.ab-row-2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(376px, 1fr));
    gap: 24px;
    padding: 0;
    align-items: center;
    list-style: none;
}

.hm_btn {
    margin: auto;
    width: fit-content;
    background-color: #222;
    color: #fff;
    border-radius: 24px;
    border: 1px solid #222;
    font-size: 14px;
    padding: 12px 24px;
    transition: .2;
    text-transform: uppercase;
}

.hm_btn:hover {
    opacity: .9;
    color: #fff;
}
.ab-profile-detail{
    position: relative;
    min-height: 65px;
    margin-bottom: 16px;
}
.ab-profile-detail h1{
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.4;
}
.ab-profile-detail p{
    font-size: .875rem;
    color: #808080;
    letter-spacing: .2px;
}
[data-id=ab-profile-detail] .col-md-6:first-child{
    padding-left: 250px;
}
.product-dealer-company-header {
    padding: 0 0px;
}
.main-cat-list{
    color: #222;
    margin-bottom: 4px;
    cursor: pointer;
    border-bottom: 1px solid #e4e4e4;
}
.main-cat-list li{
    margin-right: 16px;
}
.main-cat-list li .act-sec-tab{
    padding: 23px 0;
    font-family: "SF UI Text Medium" !important;
    font-size: 18px;
    color: #222;
    white-space: nowrap;
    font-weight: 400;
    font-family: 'Roboto';
}
.main-cat-list li.active .act-sec-tab{
    border-bottom: 3px solid #222;
    color: #222;
}
.con-info{
    display: flex;
    gap: 8px;
    margin-left: auto;
    width: fit-content;
}
.con-btn{
    position: relative;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    background-color: #f3f3f3;
    color: #222;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 1em 1.3em 1em 3.3em;
}
.con-btn  span{
    font-family: "SF UI Text Medium" !important;
    margin-left: 8px;
}
.con-btn  i{
left: 1.3em;
position: absolute;
top: 0;
right: auto;
bottom: 0;
margin: auto;
fill: currentColor;
height: fit-content;
}
.share-btn{
    height: 45px;
    line-height: 45px;
    padding: 0;
    width: 45px;
    background-color: #ededed;
    text-align: center;
    cursor: pointer;
}
@media only screen and (min-width: 1201px) and (max-width: 1480px) {
    .ab-row-1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 998px) and (max-width: 1200px) {
    .ab-row-1 {
        grid-template-columns: repeat(2, 1fr);
    }

 
}

@media only screen and (min-width: 601px) and (max-width: 997px) {
    .ab-row-1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    .ab-row-1 {
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
    }

    .ab-row-2 {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        gap: 8px;
    }
    .con-info{
        margin: auto;
    }
    .ab-profile-detail{
        position: relative;
        min-height: 55px;
        margin-bottom: 16px;
        margin-top: 16px !important;
    }
    .carousel-control-next, .carousel-control-prev{
        display: none !important;
    }
    [data-id=ab-profile-detail] .col-md-6:first-child{
        padding-left: 0;
        text-align: center;
        padding-top: 0;
        padding-bottom: 16px;
        margin-top: 83px;
    }
    .ab-profile-detail h1{
            font-size: 1.5rem;
            font-weight: 500;
        }
    
    [data-id=ab-row-15px] .row{
        margin-left: -8px !important;
        margin-right: -8px !important;
    }
    .main-cat-list{
        overflow: hidden;
        overflow-x: scroll;
    }
    .main-cat-list::-webkit-scrollbar{
        display: none;
    }
    .main-cat-list li .act-sec-tab{
        padding-bottom: 12px;
        font-weight:500;
        letter-spacing: 0px;
    }
    .main-cat-list li {
        margin-right: 8px;
    }
    .hm_btn{
        margin-bottom: 16px;
    }
    
}

/*============ end three col ==============*/