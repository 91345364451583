/*house spaces popup*/
.error-container{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: #00000454;
  z-index: 999999999999999999999;
}
.error-message {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  /* z-index: 999; */
  background-color: #fff;
  color: #fff!important;
  padding: 30px 10px;
  text-align: center;
  border-radius: 8px;
  box-shadow: rgba(0,0,0,.28) 0 8px 28px!important;
}
.error-message p {
  color: #222;
  font-family: 'SF UI Text Semibold';
  font-size: 16px;
}
.error-message p a {
  font-family: 'SF UI Text Regular';
  color: #06c !important;
  cursor: pointer;
  font-size: 14px;
}
.error-message p a:hover{
  text-decoration: underline !important;
}

/*END house spaces popup*/
/* Add Product to spaces popup  */

.add-product-spaces-popoup .ui-dialog .ui-dialog-content{
  min-width: 711px;
  max-width: 711px;
  width: 711px;
  height: 542px;
  border: none;
  padding: 0;
  overflow: hidden;
  border-radius: 16px;
  background-color: #fff;
}
.add-product-popup-body {
  padding: 30px;
  padding-bottom: 0;
}

.add-product-popup-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeefea;
  padding-bottom:16px;
  width: calc(100% - 99px);
}

.add-product-spaces-popoup h3 {
  text-align: left !important;
  font-size: 20px;
  line-height: 29px;
  color: #222;
  font-family: "SF UI Text Semibold";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: -1px;
  margin-bottom: 12px;
}
.add-more-btn {
  background: transparent;
  border: none;
  font-size: 12px;
  color: #06c;
  font-family: 'SF UI Text Regular';
}
.add-product-spaces-popoup-heading {
  height: 50px;
  padding: 10px;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.add-product-spaces-popoup-heading h6 {
  font-size: 1.2rem;
}

.add-product-spaces-popoup-message {
  display: flex;
  background: #fff;
  border-radius: 8px;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  /* width: 348px; */
  /* height: 46px; */
  justify-content: flex-start;
  padding: 0 16px;
}

.add-product-spaces-popoup-message-img img {
  height: 45px;
}

.add-product-spaces-popoup-message-text {
  display: flex;
  align-items: center;
}

.tag-all-space-strip {
  display: flex;
  background: #f6f6f6;
  width: 348px;
  height: 46px;
  justify-content: flex-start;
  padding: 0 16px !important;
  align-items: center;
  border-radius: 8px;
}

.tag-all-space-strip p {
  font-family: "SF UI Text Regular";
  color: #1a374d;
  font-size: 12px;
  line-height: 14px;
  padding-right: 12px;
  border-radius: 8px;
}
.tag-all-space-strip .p-checkbox-label  {
  height: 33px;
  /* background: #aeabfe; */
  background: #fff;
  border: 1px solid #a6a2ff;
  margin-left: 0;
  cursor: pointer;
  font-family: "SF UI Text Medium";
  font-size: 13px;
  line-height: 17px;
  color: #222;
  white-space: nowrap;
  width: inherit;
  padding: 8px 12px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  box-sizing: border-box;
  border-radius: 6px;
  margin: auto;
}
.tag-all-space-strip .p-checkbox-label:hover{
  border-color: #7b76e8;
}
.MMainSubCateGoryActive .p-checkbox-label {
  background: #aeabfe !important;
  border: 1px solid #a6a2ff !important;
  color: #fff !important;
}
.add-product-spaces-popoup .p-dialog {
  min-width: 711px;
  max-width: 711px;
  width: 711px;
  height: 542px;
  border: none;
  padding: 0;
  overflow: hidden;
  border-radius: 16px !important;
  background-color: #fff;
}
.main-space-popup .p-checkbox-label{
  height: 33px;
  /* background: #aeabfe; */
  background: #fff;
  border: 1px solid #a6a2ff;
  margin-left: 0;
  cursor: pointer;
  font-family: "SF UI Text Medium";
  font-size: 14px;
  line-height: 17px;
  color: #222;
  padding: 8px 12px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 6px;
}
.main-space-popup .p-checkbox-label:hover{
  border-color: #7b76e8;
  
}

.add-product-spaces-popoup-message-text h6 {}

.add-product-spaces-popoup-heading h3 {
  width: 100%;
  text-align: center;
}

.add-product-spaces-popoup-body {
  width: 100%;
position: relative;
z-index: 999;
}

.add-product-spaces-popoup-product {
  /* width: 142px; */
  display: grid;
  align-content: baseline;
  justify-content: center;
  position: absolute;
  right: 22px;
}

.add-product-spaces-popoup-product p {
  margin-top: 8px !important;
  font-size: 10px;
  line-height: 12px;
  color: #406882;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  font-family: 'SF UI Text Regular';
}

.add-product-spaces-popoup-product ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
}

/* width */
.add-product-spaces-popoup-product ul::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.add-product-spaces-popoup-product ul::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.add-product-spaces-popoup-product ul::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.add-product-spaces-popoup-product ul::-webkit-scrollbar-thumb:hover {
  background: #e5eedb;
}

.add-product-spaces-popoup-product ul:hover::-webkit-scrollbar-thumb {
  background: #e5eedb;
}

.add-product-spaces-popoup-product ul li {
  padding-bottom: 0rem;
  margin-left: 8px;
  margin-bottom: 8px;
}

.other-text {
  font-family: 'SF UI Text Medium';
  font-size: 14px;
  line-height: 17px;
  color: #1a374d;
  margin-top: 24px !important;
  margin-bottom: 12px !important;
}

.active-product-card {
  width: 91px;
  height: 88.86px;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 12px;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  z-index: 999;
}

.active-product-card img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 0px;
}

.active-product-card p {
  font-size: 12px;
  line-height: 14px;
  color: #1a374d;
  font-family: 'SF UI Text Medium';
  margin-top: 10px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.product-card-pop {
  width: 56px;
  height: 56px;
  border: 1px solid #cfd8dc;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.active-product-card-pop {
  position: relative;
  border: 2px solid #000;
  border-radius: 10px;
  margin-bottom: 5px;
}

.product-card-pop-img {
  width: 56px;
  height: 56px;
  position: relative;
  margin: auto;
  display: flex;
  /* margin-top: 10px; */
}

.product-card-pop-img img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  padding: 5px;
}

.collection-btn {
  left: 150px;
  color: #2c72d2 !important;
  font-stretch: normal;
  font-size: 12px;
  font-family: "SF UI Text Regular";
  cursor: pointer;
  top: 39px;
  z-index: 5;
  background: #fff;
  line-height: 14px;
}

.collection-btn:hover {
  text-decoration: none;
}

.add-product-spaces-popoup-space {
  /* width: calc(100% - 142px);
  max-width: 50rem; */
  /* padding-left: 12px; */
}

.p-100-px {
  width: 100px;
}

.pr-20px {
  padding-right: 20px;
}

.pb-20px {
  padding-bottom: 20px;
}

.mt-15-px {
  margin-top: 15px;
}

.mb-15-px {
  margin-bottom: 15px;
}

.datacheckboxing {
  width: 100%;
}

.spaces-scroller {
  /* margin-left: auto; */
  /* height: 62vh;
  width: calc(100% - 20px); */
  /* width: 510px;
height: 321px;
  z-index: 999999;
  border: 1px solid #CFD8DC; */
  margin-left: auto;
  width: 100%;
  height: 343px;
  min-height: 343px;
  z-index: 999999;
  /* border: 1px solid #CFD8DC; */
  padding: 0px 0px 10px 0px;
}

.main-space-name {
  font-family: "SF UI Text Bold";
  font-size: 14px;
  line-height: 17px;
  color: #222;
  width: 100%;
  margin: 13px 0px 13px 0px !important;

  /* margin-bottom: 8px !important; */
}

.main-space-name img {
  height: 23px;
  width: 23px;
  margin-right: 8px;
}

.main-space-popup {
  /* width: 15%; */
  margin-right: 12px;
  position: relative;
}

.main-space-popup p {
  border: none;
  box-shadow: none;
  text-align: left;
  padding: 0 0 8px 0px;
  font-size: 1rem;
  font-family: 'SF UI Text Medium';
  font-stretch: normal;
  color: #1a374d;
}

.sub-space-popup {
  width: 100%;
  flex-wrap: wrap;
  /*padding-left: 6px !important;*/
  /* float: left; */
}
.add-product-spaces-popoup-footer {
  /* position: fixed; */
  /* width: 709px; */
  height: 65px;
  margin: auto;
  /* margin-top: 7px; */
  bottom: 0;
  width: 100%;
  background-color: #fff !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: #fff !important;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 16px 16px;
  padding: 0px 32px;
  position: relative;
  z-index: 999;
}

.add-product-spaces-popoup-footer p {
  font-size: 12px;
  color: #222;
}

.add-product-spaces-popoup-footer .btn {
  font-family: "SF UI Text Semibold";
  font-size: 14px;
  line-height: 17px;
  border-radius: 8px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-product-spaces-popoup-footer .cancel-bttn {
  border: 1px solid #c8c6c6 !important;
  color: #222 !important;
  width: 90px !important;
  border-radius: 26px;
}

.done-btn {
  width: 140px;
  background: #fc9918;
  color: #fff;
}
.add-product-spaces-popoup-footer .popup-save-btn{
  color: #222;
  background: #FFcf80;
  border: 1px solid #ffbf56;
  border-radius: 26px;
  font-size: 13px;
}
.done-btn:hover {
  border: 1px solid #eb8f17 !important;
}
.add-product-spaces-popoup-footer .popup-save-btn:hover{
  border: 1px solid #eb8f17!important;
  background-color: #fc9a1814;
}
.save-idea-sub-bg{
  position: fixed;
  right: 0;
  z-index: 99;
  bottom: 0;
}
.preview-btn {
  text-align: right;
}

.preview-btn button {
  color: #f8f9fa !important;
  background-color: #007ad9 !important;
  border-color: #007ad9 !important;

  font-family: "SF UI Text Regular" !important;
  text-transform: initial !important;
  padding: 5px 26px !important;
  border-radius: 8px !important;
  font-size: 0.95rem !important;
  margin-top: 0;
}

.add-product-spacing {
  text-align: right;
}

.add-product-spacing a {
  top: 1.688rem;
  left: 10.188rem;
}

.all-space-add-space {
  align-items: center;
  margin-top: 12px;
}


.check-box-card {
  height: 33px;
  /* background: #aeabfe !important; */
  background: #fff;
  border: 1px solid #a6a2ff !important;
  width: fit-content;
  border-radius: 6px;
  margin-right: 12px;
  margin-bottom: 8px;
  transition: 0.3s;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  cursor: pointer;
}

.check-box-card:hover {
  border-color: #7b76e8;

}

.check-box-card:hover svg {
  fill: #fc9918;
}

.check-box-card label {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  height: 33px;
  padding-left: 12px;
  padding-right: 12px;
}

.check-box-card label .check-box-card-img {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.check-box-card label img {
  width: 17px;
  height: 17px;
  margin: auto;
  margin-right: 8px;
}

.check-box-card label span {
  font-family: "SF UI Text Regular";
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #222;
}

.check-box-card label span i {
  margin-right: 5px;
  font-size: 0.8rem;
  margin-top: 3px;
}

.check-box-card input {
  display: none;
}

.checked-svg {
  position: absolute;
  left: 3px;
  top: -10px;
  transform: translateX(-50%) scale(1);
  transition: 0.5s;
  opacity: 0;
}

.active-checked-svg{
  opacity: 1;
  transform: translateX(-50%) scale(1);
}
.check-box-card i {
  color: #007ad9;
}

.active-check-box-card {
  background: #aeabfe;
  border: #a6a2ff;

}

.active-check-box-card:hover {
  background: #aeabfe;
  border: #a6a2ff;
  color: #fff;
}

.active-check-box-card i {
  color: #f8be45 !important;
}
.active-check-box-card label span{
  color: #fff;
}
.popsub-headingsticky {
  height: 99px;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 999;
  width: 100%;
}

.added-btn {
  background-color: transparent;
  padding: 0;
  /* margin-top: -4px; */
  /* margin-left: 8px; */

}

/* .added-btn:hover{
  color: #007ad9 !important;
} */
.added-btn:hover svg {}

.added-btn i {
  color: #007ad9 !important;
}

.spc-text p {
  text-overflow: ellipsis;
  width: 64px;
  white-space: nowrap;
  overflow: hidden;
}

.products-in-space {
  width: 150px !important;
  box-shadow: 0 1px 6px 0 #63636326 !important;
  border-radius: 6px;
  cursor: default;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
  position: absolute;
  display: flex;
  top: 0px;
  z-index: 999;
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 0px;
  transform: translateX(-84px) translateY(-54px);
  border: 1px solid #ccc;
}

.products-in-space::before,
.products-in-space::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0.5rem 0.5rem 0;
  bottom: -7px;
  right: 50px;
}

.products-in-space::before {
  bottom: -8px;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.products-in-space::after {
  border-top-color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.products-in-space-more-then-3 {
  transform: translateX(-84px) translateY(-101px);
}

.space-list {
  padding-top: 5px !important;
  padding-bottom: calc(12px - 0.5rem) !important;
  border-bottom: 1px solid #eeefea;
}

.space-list:last-child {
  padding-bottom: 0px !important;
  border-bottom: none;
}

.close-add-space {
  position: fixed;
  right: 0;
  top: 0;
}

.activer-pis {
  visibility: visible;
}

.product-spi-list {
  position: relative;
  display: table;
  padding: 3px;
}

.product-spi-image {
  height: 40px;
  width: 40px;
  border: 1.5px solid #ccc;
  margin-right: 0.5rem;
  background-color: rgba(var(--b3f, 250, 250, 250), 1);
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.product-spi-image img {
  /* position: absolute;
  top: 0;
  margin: auto;
  right: 0;
  border: 2px solid #fff;
  left: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  bottom: 0; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  /* border-radius: 50%; */
  margin: auto;
}

.current-product-spi-image {
  border-color: #fbc12d;
}

/* End add Product to spaces popup  */

/*feedback popup in footer*/

/*END feedback popup*/
.newworkcreatedsearch .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #222;
  border: 1px solid #222;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #222;
  background: none !important;
  border: 1px solid #222;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  border-radius: 50% !important;
  color: #222 !important;
  background: #fff !important;
  border: none !important;
  box-shadow: none;
  outline: none;
}
/*global css for popups close*/
.close-popup{
  display: flex;
  position: absolute;
  right: 8px;
  top: 10px;
  transition: .3s;
  cursor: pointer;
  z-index: 99;
  padding: 4px;
  border-radius: 20px;
}
.close-popup img {
  width: 20px;
  height: 20px;
  margin: auto;
}
/*END global css for popups close*/
@media only screen and (min-width: 600px) and (max-width: 1280px) {

  /* .add-product-spaces-popoup-product ul{
    height: calc(100vh - 40vh);
  }
  .spaces-scroller {
    height: 40.3vh;
  } */
  .add-product-spacing a {
    left: 11.188rem;
  }

  /* .pcc-l-count{
    font-size: 12.9px !important;
  }
  .font-1mm{
    font-size: 16px !important;
  } */
}

@media only screen and (max-width: 600px) {
  .tag-all-space-strip .p-checkbox-label:hover{
    background: #fff !important;
    border: 1px solid #a6a2ff !important;
    box-shadow: none;
  }
  .tag-all-space-strip .p-checkbox-label{
    box-shadow: 0 2px 3px #0000001f!important;
  }
  .main-space-popup .p-checkbox-label:hover{
    background: #aeabfe !important;
    border: 1px solid #a6a2ff !important;
    /* border-color: #ffbf56;
    background-color: #fc9a1814; */
  }
  .space-list:last-child {
    margin-bottom: 60px;
  }
  /*Feedback popup*/
  .prime-dio-feedback .p-dialog {
    height: 100vh!important;
    width: 100%!important;
}
 /*End Feedback popup*/
  .error-message{
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    bottom: 0px;
    border-radius: 24px 24px 0 0;
    min-height: 21vh;
}
.error-message p {
  font-family: 'SF UI Text bold';
}
  .error-message p a {
    display: block!important;
    font-size: 14px!important;
    font-family: 'SF UI Text Medium';
}
  .add-product-space-container {
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 100px;
  }
  .main-space-name{
    font-size: 17px;
    margin: 1px 0px 13px 0px !important;
  }

  .main-space-name img{
    height: 35px;
    width: 35px;
  }
  .pr-20px {
    padding-right: 0;
  }

  .close-add-space {
    right: 16px;
    top: 16px;
    display: none;
  }

  .main-space-popup {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-left: 10px; */
    /* margin-top: -7px; */
    margin-bottom: 2px;
    height: 33px;
    /* box-shadow: 0 2px 3px #0000001f!important; */
    border-radius: 6px;
  }

  .sub-space-popup {
    width: 100%;
    align-items: center;
    padding-left: 6px !important;
  }

  .space-w-48 {
    width: 93px !important;
    margin-bottom: 4%;
  }

  .space-list {
    /* border-bottom: 1px solid #fff; */
    border-bottom: 1px solid transparent;
    padding-top: 8px !important;
  }

  .add-product-spaces-popoup-product {
    width: 30%;
  }

  /* Product list */
  .add-product-spaces-popoup-product ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .product-card-pop {
    height: 96px;
    width: calc(100% - 8px);
    margin-bottom: 10px;
  }

  .product-card-pop-img {
    height: 56px;
    width: 85%;
  }

  .product-card-pop-text h6 {
    font-size: 0.8rem;
  }

  .active-product-card-pop {
    border: 2px solid #000;
    margin-bottom: 5px;
  }

  /* end product list */

  /* Message & Preview Button */
  .add-product-spaces-popoup-message {
    width: 100%;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #bfda9f;
  }

  .add-product-spaces-popoup-message-img img {
    height: 36px;
  }

  .add-product-spaces-popoup-message-text h6 {
    font-size: 0.8rem;
  }

  .add-product-spaces-popoup-message-text p {
    font-size: 0.8rem;
    line-height: 1.2;
  }

  /* End message & Preview Button */
  /* Spaces list */

  /* end spaces list */

  .add-product-spaces-popoup-footer {
    margin-top: 0;
    display: flex;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #ccc;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    z-index: 9999;
    text-align: center;
    margin-bottom: 0px !important;
    /* box-shadow: 0px -3px 0px rgb(0 0 0 / 8%); */
  }

  .add-product-spaces-popoup-footer .done-btn {
    border: 1px solid #d0d0d0 !important;
    background: #f2f2f2;
    border-radius: 3px;
    border: none;
    color: #222;
    font-size: 12px;
    line-height: 17px;
    padding: 12px 18px;
    font-family: "SF UI Text Semibold";
    cursor: pointer;
    width: fit-content;
    height: 41px;
  }

  .add-product-spaces-popoup-footer .btn-light {
    padding: 12px 18px;
  }

  .preview-btn {
    float: left;
  }

  .preview-btn button {
    border-radius: 5px !important;
    padding: 0.375rem 17px !important;
  }

  .spaces-scroller {
    margin-left: auto;
    height: calc(100vh - 76%) !important;
    padding: 1px 0px 2px 0px;
  }

  .check-box-card {
    height: 33px;
    box-shadow: 0 2px 3px #0000001f!important;
    /* border-color: transparent !important; */
    width: calc(100% - 9px);
    margin-bottom: 12px;
    box-shadow: none;
    /* box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 2px 3px rgb(0 0 0 / 10%) !important; */
  }
 
  .check-box-card label {
    align-content: center;
  
  }

  .products-in-space {
    width: 101px !important;
    top: 2px;
    transform: translateX(-70px) translateY(-47px);
  }

  .products-in-space-more-then-3 {
    transform: translateX(-70px) translateY(-74px);
  }

  .products-in-space::before,
  .products-in-space::after {
    right: 17px;
  }

  .product-spi-image {
    width: 25px;
    height: 25px;
  }

  .check-box-card label .check-box-card-img {
    width: 17px;
    height: 17px;
    display: flex;
    margin-right: 8px;
  }

  .check-box-card label img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .datacheckboxing {
    width: 98%;
    justify-content: flex-end;
    margin-left: auto;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
 


}

@media only screen and (max-width: 600px) {
  .success-popup .p-dialog {
    width: calc(100% - 32px) !important;
  }
  .add-product-spaces-popoup-footer {
    padding: 10px 16px;
  }
  .add-product-spaces-popoup .p-sidebar{
    max-height: 80vh !important;
  }
  .add-product-popup-body {
    /* padding: 24px 16px; */
    padding: 24px 16px 0px 16px;
    background:#fff;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    height: 100%;
  }
  .add-product-popup-body {
    max-height: 80vh;
    /* max-height:77vh; */
    /* height: 100vh; */
    /* height: 77vh; */
    height: 80vh;
  }
  .add-product-spaces-popoup-body::-webkit-scrollbar{
    display: none !important;
  }
  .add-product-spaces-popoup h3 {
    font-size: 27px;
    line-height: 150%;
    margin-left: 0px;
  }

  .add-product-popup-heading {
    border-bottom: none;
    /* border-bottom: 1px solid #fff; */
    width: calc(100% - 100px);
    padding-bottom: 6px;
    /* padding-bottom: 0px; */
  }

  .all-space-add-space {
    /* width: 100%; */
    width: 80%;
  }

  .add-product-spaces-popoup-product {
    width: 80px;
    height: 80px;
    top: 68px;
  }

  .active-product-card {
    background-color: #fff;
    margin-top: 8px;
  }

  .tag-all-space-strip {
    display: grid;
    height: 88.86px;
    padding: 12px 8px 12px 8px !important;
    /* padding: 0px 16px 16px 0px !important; */
    padding-left: 0px;
    width: 100%;
    background: #fff;
  }
  .MMainSubCateGoryActive .p-checkbox-label {
    background: #aeabfe !important;
    border: 1px solid #a6a2ff !important;
    color: #fff;
  }
  .MMainSubCateGoryActive .p-checkbox-label:hover{
    background: #aeabfe !important;
    border: 1px solid #a6a2ff !important;
    color: #fff;
  }
  .tag-all-space-strip p {
    padding-right: 0px;
    font-size: 13px;
  }

  .add-product-spaces-popoup-body {
    padding: 0 0px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .add-product-spaces-popoup-space {
    width: 100%;
    padding: 0;
    /* border-top: 1px solid #9e9e9e; */
  }

  .spaces-scroller {
    width: calc(100% - 0px);
  }

  .check-box-card label span{
    font-family: 'SF UI Text Medium';
    font-size: 14px;
    border: none;
    /* border: 1px solid c8c6c6 !important; */
  }
}

@media only screen and (max-width: 320px) {
  .add-product-spaces-popoup-body {
    min-width: 100% !important;
    padding: 0 10px;
    height: auto;
  }

  .add-product-spaces-popoup-product {

    position: sticky;
    top: 60px;
    align-self: flex-start;
  }

  .add-product-spaces-popoup-product ul {
    height: calc(100vh - 32vh) !important;
  }

  .spaces-scroller {
    height: calc(100vh - 199px) !important;
    width: calc(100% - 20px);
  }

  .preview-btn button {
    padding: 5px 10px !important;
  }

  .add-product-spaces-popoup-footer {
    position: fixed;
    z-index: 9999;
    background-color: #fff;
  }

  .add-product-spaces-popoup-footer .done-btn {
    width: 96%;
  }

  .datacheckboxing {
    width: 98%;
    justify-content: flex-end;
    padding-left: 0px;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
  .check-box-card label span{
    font-family: 'SF UI Text Medium';
  }
  .NewBranImG {
    box-shadow: 0px 4px 16px rgb(0 0 0 / 8%) !important;

  }
}

.info-tab {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  position: absolute;
  top: 1.6rem;
  left: 0;
}

.info-tab::before,
.info-tab::after {
  display: inline-block;
  color: #fff;
  line-height: normal;
  font-family: "icomoon";
  position: absolute;
}

.note-icon {
  background: #47ade0;
}

.note-icon::before {
  font-size: 2.3rem;
  content: "\e911";
  top: 8px;
  left: 8px;
}

.info-tab i::before,
.info-tab i::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -15px;
  transform: rotateX(60deg);
}

.note-icon::after {
  font-size: 1.7rem;
  content: "\e912";
  top: 9px;
  left: 19px;
}

.info-tab i::before {
  width: 20px;
  height: 20px;
  box-shadow: inset 12px 0 13px rgb(0 0 0 / 50%);
}

.note-icon i::before {
  background: #1d7ead;
}

.info-tab i::before,
.info-tab i::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -15px;
  transform: rotateX(60deg);
}

.info-tab i::after {
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  bottom: -18px;
}
.datacheckboxing  .ui-chkbox, .datacheckboxing .ui-chkbox .ui-chkbox-box, .tag-all-space-strip .ui-chkbox, .tag-all-space-strip .ui-chkbox .ui-chkbox-box{
  display: none !important;
}
@media (hover: hover) and (pointer: fine) and (max-width: 600) {
  .check-box-card:hover {
    background-color: inherit !important;
  }
}

.save-img-1 {
  display: block;
  margin-top: 5px !important;
  margin-right: 0px !important;
}

.added-btn:hover .save-img-1 {
  display: none;
}

.save-img-2 {
  display: none;
  margin-top: 5px !important;
  margin-right: 0px !important;
}

.added-btn:hover .save-img-2 {
  display: block;
}

/*success popup*/
.success-popup .p-dialog{
  border-radius: 16px;
  overflow: hidden;
  width: 22vw;
}
.success-popup .p-dialog .p-dialog-content {
  background: #fff;
  color: #495057;
  padding: 0 1.5rem 2rem !important;
}
.success-msg-container {
  width: 246px;
  margin: auto;
  text-align: center;
  padding-top: 32px;
  position: relative;
}
.success-img {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto auto 15px;
}
.success-img img {
  max-width: 100%;
}
.success-msg-container h4 {
  font-size: 22px;
  margin-bottom: 24px;
}
.success-msg-container p {
  font-size: 16px;
}
.success-popup .p-dialog .p-dialog-footer {
  text-align: center;
}
.success-popup .p-dialog .p-dialog-footer button {
  margin: 0;
  width: auto;
  border-radius: 24px;
  border: 1px solid #222;
  background-color: #222;
  color: #fff;
  font-family: 'SF UI Text Regular';
  height: 40px;
  font-size: 16px;
  padding: 0.5rem 1rem;
}
.success-popup .p-button.p-button-text:enabled:hover {
  border: 1px solid #222;
  background-color: #222;
  color: #fff;
  opacity: .8;
}

/* info popup */

.info-popup .p-dialog{
  width: 650px;
  min-width: 650px;
  max-width: 650px;
  z-index: 1101;
  height: fit-content;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 22px;
  margin: auto !important;
  background-color: #fff;
  /* background-image: url(https://cdn.claspo.io/img/77/forms/12282/c0e65706-631f-449c-ab7e-51efe2c1a758.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 24px 0px;
}
.info-popup .p-dialog .p-dialog-header{
  padding: 32px;
  padding-bottom: 0px;
  justify-content: center;
  font-size: 35px;
  font-family: 'SF UI Text Regular';
  background-color:#fff;
  display: flex !important;
 
}
.info-popup .p-dialog .p-dialog-header span{
  letter-spacing: -0.5px;
  color: #222;
  font-size: 27px !important;
  /* font-family: 'SF UI Text Light'; */
}
.info-popup .p-dialog .p-dialog-header p{
  font-size: 16px;
  font-family: 'SF UI Text Regular';
}

.info-popup .p-dialog .p-dialog-content{
  background-color:#fff;
  padding: 24px !important;
  /* padding: 60px !important; */
  padding-top: 24px !important;
  padding-bottom: 43px !important;
}
.info-popup .p-dialog .p-dialog-header-icons{
  display: flex !important;
  position: absolute;
  right: 32px;
  top: 32px;
}

/* Bottom slider */
.info-popup .p-sidebar-bottom{
height: fit-content;
max-height: 92vh;
border-radius: 22px 22px 0px 0px;
}
.info-popup .p-sidebar .p-sidebar-header{
  display: flex !important;
  justify-content: center;
}
.info-popup .p-sidebar .p-sidebar-content{
  padding: 16px !important;
}
/* End bottom */
.info-row{
  display: flex;
  width: fit-content;
  align-items: center;
  margin: auto;
}
.info-card-col{
width: fit-content;
margin-right: 24px;
}
.info-gif-col{
  width: calc(100% - 186px);
  margin: auto;
}
.info-back-btn{
  position: absolute;
  left: 32px;

}
.info-back-btn .pi{
font-size: 1.4rem;
}
.info-popup .p-icon {
  width: 1.3rem;
  height: 1.32rem;
}
.info-ul-1{
  display: flex;
  width: fit-content;
  margin: auto;
  margin-bottom: 24px;
}
.info-ul-1 li{
  margin-right: 8px;
}
.info-ul-1 li:last-child{
  margin-right: 0px;
}
.info-ul-2{
  padding: 32px 32px;
  margin-left: -32px;
  margin-right: -32px;
  /* background-color: #f5e8ff; */
  display: flex;
  margin-bottom: 0px;
  border: none;
  box-shadow: none;
}
.info-card{
  padding: 0px;
  display: block;
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 22px 22px 0px 0px;
  text-align: center;
  border: 1px solid rgb(220, 217, 217);
  /* border: 1px solid #ffecd4; */
  cursor: pointer;
  /* box-shadow: 0 1px 12px #e6e6e6; */
}
.info-card:hover{
  background-clip: padding-box;
  border-color: #1a374d;
  box-shadow: 0 0.375rem 1rem 0 rgba(58,53,65,.12);
  text-decoration: none;
}
.info-card-pro{
border: none !important;
pointer-events: none;
padding: 0;
background-color: transparent;
box-shadow: none;

}
.info-prdut-img{
  margin: auto;
  /* width: fit-content; */
  margin-bottom: 16px;
  height: 18px;;

}
.info-prdut img{
  margin-right: 6px;
  height: 28px;
  margin-bottom:0px;
}
.info-img{
  /*margin: auto;
  width: fit-content; 
  margin-bottom: 19px;
  height: 70px;*/
  margin: auto;
  margin-bottom: 11px;
  width: 268px;
  height: 214px;
}
.info-img img{
  /* height: 75px;
  margin-bottom: 15px; */
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 22px 22px 0px 0px;
  object-fit: cover;
}
.info-img-serch{

}
.info-img-serch img{
  width: 100%;
}
.info-content h3{
  font-size: 16px;
  font-family: 'SF UI Text Medium';
  color: #222;
  margin-bottom: 11px;
  letter-spacing: -0.5px;
}
.info-content p{
  font-size: 14.5px;
  color: #5c5c5c;
  font-family: 'SF UI Text Regular';
  letter-spacing: -0.5px;
  
}
.full-renovartion-strip{
  height: fit-content;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  background-color: #fff;
  /* background-color: #57257f; */
  width: fit-content;
  margin: auto;
  padding: 52px 24px;
  border-radius: 22px;
  box-shadow: 0 0 7px 2px #757575;
}
.full-renovartion-strip h3{
color: #222;
}
.full-renovartion-strip p{
  color: #222 ;
}
.footer-msg{
  color: #222;
  text-align: center;
  border-radius: 22px;
  margin: auto;
  width: fit-content;
  font-size: 15px;
  font-family: "SF UI Text Regular";
  margin-bottom: 0;
  letter-spacing: -0.5px;

}
.footer-msg h3{
  font-size: 17px;
  font-family: 'SF UI Text Medium';
  letter-spacing: -0.5px;
  margin-bottom: 5px;
}
.footer-msg p{
  font-size: 13px;
  font-family: 'SF UI Text Light';
  letter-spacing: -0.5px;
}
.fns-btn-box{
  width:fit-content;
  margin: auto;
  margin-top: 16px;
}
.fns-btn-box button{
  width:fit-content;
  padding: 8px 12px;
  border: none;
  color: #fff;
  font-family: 'SF UI Text Medium';
  border: 1px solid transparent;
  border-radius: 9px;
  background-color: #06c;
  width: 72px;
  font-size: 14px;
}
.fns-btn-box button:hover{
  border-color: #222;
}
.fns-btn-box button:first-child{
  margin-right: 12px;
}
.continue-strip{
  text-align: right;
  padding-top: 32px;
}
.continue-strip span{
  margin-right: 16px;
}
.continue-btn{
  color: #fff!important;
  background: #222!important;
  border: 1px solid #222 !important;
  border-radius: 26px !important;
  /* background: #FFcf80 !important;
  border: 1px solid #ffbf56 !important; */
  padding: 5px 24px 8px !important;
}
.later-btn{
  padding-right: 24px;
  color: #222;
  background: transparent !important;
  border: 1px solid transparent !important;
}
.continue-btn:hover{
  opacity: .9 !important;
}
.ioc-gif-box{
  width: 100%;
  /* aspect-ratio: 10/8; */
  /* border: 1px solid #d2d2d2; */
  padding: 12px;
  border-radius: 9px;
  /* box-shadow: 0 0.375rem 1rem 0 rgba(58,53,65,.12); */
}

.ioc-gif-box img{
  width: 100%;
  height: 100%;
}
.info-popup .ab-project-box{
  display: flex;
}
.info-popup .ab-project-ul{
  grid-template-columns: repeat(auto-fill, minmax(21%, 1fr));
  height: fit-content;
  gap: 12px;
  display: grid;
  width: 100%;
  margin-left: -32px;
  margin-right: -32px;
  margin: auto;
}
.info-popup .hometype-card img{
  height: 26px;
}
.info-popup .hometype-card span {
  font-family: 'SF UI Text Medium';
    font-size: 13px;
}
.p-component-overlay{
  background-color: rgba(0, 0, 0, 0.11) !important;
  backdrop-filter: blur(4px);
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.info-popup .p-dialog-mask.p-component-overlay,
.basic-info-popup .p-dialog-mask.p-component-overlay{
  background-color: rgba(0, 0, 0, 0.11) !important;
  backdrop-filter: blur(4px);
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1439px) {
 
  .info-card{
    padding: 0px;
  }
  .info-content h3{
    
  }
  .info-content p{
  }
}
@media only screen and (max-width: 1280px) {
  .info-popup .p-dialog{
    padding: 24px;
  }
  .info-card{
    padding: 0px 0px;
  }
  .ioc-gif-box {
    width:100%;
  }
}
@media only screen and (max-width: 980px) {
  .info-popup .p-dialog{
    
    padding: 24px;
  }
}
@media only screen and (max-width: 600px) {
  .p-component-overlay{
    background-color: rgb(0 0 0 / 7%) !important;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    opacity: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
  }
  .info-popup .p-dialog{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .info-popup .p-sidebar .p-sidebar-header{
    padding-top: 32px;
    padding-bottom: 0px;
  }
  .info-popup .p-dialog .p-dialog-content{
    padding: 16px;
  }
  .info-popup .p-sidebar .p-sidebar-header span{
    font-size: 24px;
    font-family: 'SF UI Text Semibold';
    color: #222;
  }
  .info-popup .p-sidebar .p-sidebar-header p{
    color: #222;
    font-size: 14px;
  }
  .info-row{
    flex-wrap: wrap;
  }
  .info-card-col{
    width: fit-content;
    margin: auto;
    margin-top: -16px;
  }
  .info-ul-1{
   display: grid;
   grid-template-columns: repeat(2,1fr);
   gap: 8px;
  }
  .info-ul-2{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  .info-ul-2 li{
    width: 48%;
  }
  .info-gif-col{
    width: 100%;
  }
  .info-ul-1 li{
    margin-right: 0px;
  }

  .info-card {
    padding: 0px 0px;
    box-shadow: none;
}
.info-card-save-idea{
  display: flex;
}
.info-prdut{
  margin-bottom:0px;
}
  .info-prdut img {
    height: 26px;
}
.info-img{
  width: 163px;
  height: 130px;
  margin-bottom: 10px;
  /* margin-bottom:0px; */
}
  .info-img img {
    /* height: 65px; */
    width: 163px;
    height: 130px;
}
.info-card-col .info-img img {
  height: 60px;
}
.info-content{
  text-align: center;
}
.info-content-save-idea{
  text-align: left;
  padding-left: 16px;
}
.info-content h3{
  font-size: 15px !important;
  font-family: 'SF UI Text Semibold';
  margin-bottom: 10px;
  letter-spacing: -0.25px;
}
.info-ul-2 li a .nfo-content p {
  font-size: 12px !important;
  font-family: "SF UI Text Medium";
  line-height: 17px;
  letter-spacing: -0.25px; 
}
.info-content p{
  font-size: 12px !important;
  font-family: "SF UI Text Regular";
  line-height: 17px;
  letter-spacing: -0.25px;
}
.info-popup .ab-project-ul{
  grid-template-columns: repeat(auto-fill, minmax(28%, 1fr));
  padding:16px;
  gap: 8px;
  border-radius: 9px;

}
.info-popup .hometype-card span {
  font-size: 14px;
}
.info-popup .hometype-card img{
  height: 32px;
}
  .footer-msg{
    font-size: 12.5px;
    margin-bottom: 0px;
    width: 100%;
    border-radius: 0;
    font-family: "SF UI Text Regular";
    letter-spacing: -0.25px;
    
  }
  .footer-msg h3{
    font-size: 16px;
    letter-spacing: -0.5px;
  }
  .footer-msg p{
    font-size: 13px;
    font-family: 'SF UI Text Medium';
    letter-spacing: -0.25px;
  }
  .continue-strip{
    justify-content: end;
    text-align: center;
    padding: 0;
    padding-top: 16px;
  }
  .continue-btn{
    width: 93px;
    height: 35px;
    padding: 4px 8px !important;
    font-size: 13px !important;
    font-family: 'SF UI Text Regular' !important;
  }
  .info-back-btn {
    left: 20px;
    font-size: 1.2rem;
    top: 36px;
}
.info-back-btn svg{
  width: 28px;
  height: 28px;
  margin-bottom: 6px;
}
.full-renovartion-strip{
  padding: 24px;
}
.continue-strip button{
  /* width: 80%; */
  width: 25%;
  font-size: 14px !important;
}
.ioc-gif-box{
  margin: auto;
}
.fns-btn-box button{
  font-family: 'SF UI Text Semibold';
}

}
/* ------------- bg color -------------------- */   
.bg-color-card-user{
  background-color: #DDEAF6;
}
.bg-color-card-pro{
  background-color: #e9d5f9;
}
/* ------------- end bg color ----------------- */ 