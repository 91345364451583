.sec-menu-stripe ul {
    width: calc(100% - 40px);
    margin-bottom: 24px;
    border-bottom: 1px solid transparent;
}
.sec-menu-stripe li {
    background-color: transparent !important;
    box-sizing: content-box;
    color: #24272c;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-size: 15.5px;
    font-family: "SF UI Text Regular";
    text-transform: none;
    font-weight: 400;
    margin-right: 24px;
    padding: 15px 0 16px;
    position: relative;
    letter-spacing: -0.1px;
    border-bottom: 3px solid transparent;
    white-space: nowrap;
}

.sec-menu-stripe li:last-child {
    margin-right: 0px;
}

.active-ms {
    border-color: #222 !important;
}
@media screen and (min-width: 601px) and  (max-width: 1200px) {
    .sec-menu-stripe li {
        padding: 2px 16px 2px;
        font-size: .75rem;
        margin-right: 8px;
        border: 1px solid #eaeaea;
        border-radius: 12px;
    }
}