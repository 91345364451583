.search-input-box{
    width: 100%;
    position: relative;
  }
  .suggestion-box{
    width: 30%;
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
  }
  .suggestion-box::-webkit-scrollbar{
    display: block;
    width: 2px;
  }
  .seach-input{
    width: 100%;
    border-radius: 24px;
    padding-left: 32px !important;
    border: 1px solid transparent;
    padding-right: 39px !important;
    height: 47px;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    box-shadow: 0 0 5px #e4e5e7;
  }
  .search-suggestions-section{
   padding: 24px 0px;
  }
  .search-suggestions-popup{
    width: 100%;
    background-color: #fff;
    border: 1px solid #ebe8e8;
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.3215686275);
    border-radius: 22px;
    position: absolute;
    padding: 32px;
    z-index: 999;
    display: flex;
    margin: auto;
  }

  .search-suggestions-popup::-webkit-scrollbar {
    display: block;
    width: 2px;
  }
  
  
  .search-suggestions-popup::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .search-icon {
    top: 0px !important;
  }

  .globle-search {
    z-index: 99 !important;
  }
.ug-items{
  margin-bottom: 24px;
}
  .sug-items-head{
    font-size: 16px;
    color: #222;
    font-family: 'SF UI Text Semibold';
    margin-bottom: 12px;
    display: block;
  }
  
  .suggestion-link{
    display: flex;
    align-items: center;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    max-width: 147px;
    margin-bottom: 6px;
    overflow: hidden;
  }
  .suggestion-link:hover{
    border: 1px solid #222;
    box-shadow: 0 3px 6px #211e2233;
  }
  .suggestion-link span{
    color: #535353;
    background: #e9e9e9;
    padding: 16px;
    width: 144px;
    height: 54px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: 'SF UI Text Medium';
    font-size: 16px;
    letter-spacing: -1px;
  }
  .suggestion-link .img-box{
    width: 55px;
    height: 50px;
    display: flex;
    margin-right: 8px;
    margin-left: 8px;
  }
  .suggestion-link .img-box img{
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
  .suggestion-box-sec2{
    width: 100%;
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
  }
  .suggestion-box-sec2::-webkit-scrollbar{
    display: block;
    width: 2px;
  }
  .suggestion-box-products{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0px;
    align-items: center;
    list-style: none;
    justify-content: space-between;
  }
  
  .sug-products{
      color: #222;
      text-align: center;
      cursor: pointer;
      padding: 0;
      width: calc((100% / 3) - 4px) !important;
      border: 1px solid #f5f5f5;
      margin-bottom:6px ;
      border-radius: 12px;
    
}
  .sug-p-img{
    aspect-ratio: 8 / 6;
    display: flex;
    justify-content: center;
    padding-bottom: 4px; 
    overflow: hidden; 
    border-radius: 12px 12px 0px 0px;
  }
  .sug-p-img img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    margin: 0px;
  }
  .sug-p-text{
    margin-top: 0px;
    text-align: left;
    background: #f5f5f5;
    padding: 11px;
    border-radius: 0px 0px 12px 12px;
  }
  .sug-p-text p{
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    color: #535353;
    font-family: 'SF UI Text Semibold';
    line-height: 21px;
    letter-spacing: -1px;
  }
  .amout{
    font-size: 16px;
    color: #535353;
    letter-spacing: -1px;
    font-family: 'SF UI Text Semibold';
  }
  @media only screen and (max-width: 1550px) {
    .search-suggestions-popup{
      width: 130%;
      margin-left: -14%;
    }
    .suggestion-box{
      padding-right: 16px;
    }
    .sug-p-text p{
      font-family: 'SF UI Text Medium';
      font-size: .84rem;
    }
    .amout{
      font-family: 'SF UI Text Medium';
      font-size: .84rem;
    }
    .suggestion-box-sec2{
      width: calc(100% - 30%);
    }
    .suggestion-link span{
      width: 100%;
      font-size: 14px;
    }

  }
  @media only screen and (max-width: 1460px) {
   
  .globle-search{
    z-index: 99 !important;
  }
  .suggestion-link{
    width: 100% !important;
  }
 
}
  @media only screen and (max-width: 600px) {
    .seach-input{
        height: 40px;
    }
    .search-icon{
        top: 0px !important;
    }
    .search-suggestions-popup{
    left: 0;
    width: calc(100% - 32px);
    margin-left: inherit;
    margin: auto;
    right: 0;
    z-index: 9999;
    padding: 14px;
    flex-wrap: wrap;
    max-height: 84vh;
    overflow: hidden;
    overflow-y: scroll;
    }
    .suggestion-box{
      width: 100%;
    }
    .suggestion-box-products{
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      margin-left: 0;
      gap: 0px;
    }
    .suggestion-link span{
      font-size: 14px;
      height: 50px;
      padding: 14px;
    }
    .sug-p-img {
    aspect-ratio: 7 / 6;
  
   }

   .suggestion-box {
     width: 100%;
   }

   .suggestion-box-products {
    width: 100%;
    margin-left: 0;
   }
  .sug-products{
    width:calc((100% / 3) - 2px) !important;
    margin-bottom:4px;
  }

  .sug-p-text p{
  font-size: 14px;
 }
 .amout{
  font-size: 14px;
}
.ug-items {
  margin-bottom: 32px;
}
}