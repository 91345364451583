.rating-popup .p-dialog{
    max-width: 420px;
    padding: 2em;
    background-color: white;
    box-shadow: 2px 0 15px -2px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    .p-dialog-content{
        padding: 0!important;
    }
}
.p-hidden-accessible{
display: none;
}

form {
     h1 {
      text-align: center;
      font-family: 'SF UI Text Bold';
      color: #443c68;
      font-size: 18px;
    }
  
     p {
      text-align: center;
      margin-bottom: 2.4em;
      line-height: 1.3;
      font-weight: 600;
      font-size: .85rem;
    }
  
     .textarea-group {
      margin-top: 2.4em;
  
      label {
        display: block;
        width: 100%;
      }
  
     label  span {
        display: block;
        font-size: 0.9em;
        font-weight: 600;
        margin-bottom: 0.8em;
      }
  
      label textarea {
        box-sizing: border-box;
        display: block;
        padding: 1em;
        line-height: 1.8;
        width: 100%;
        resize: none;
        border: none;
        background-color: #ebebeb;
      }
    }
  
    div.action-group {
      margin-top: 2em;
      display: flex;
      flex-direction: column;
      row-gap: 1em;
  
       input[type="reset"] {
        padding: 1em 2em;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 0.9em;
        font-weight: 600;
        opacity: 0.8;
  
        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
  
      input[type="button"] {
        border: 2px solid transparent;
        padding: 1em 2em;
        border-radius: 12px;
        cursor: pointer;
        font-weight: 700;
        color: white;
        background-color: #443c68;
        transition: all 0.1s ease-in-out;
  
        @media (hover: hover) {
          &:hover {
            background-color: #534485;
            border: 2px solid #443c68;
          }
        }
      }
    }
  }
  
  /* Input Rating */
  .rating {
    $rating-star-color: #d3d3d3;
    $rating-star-active-color: #4a4a4a;
    $rating-star-selected-color: orange;
  
    user-select: none;

  }
  
  /* End Input Rating */
  
    /*  ================================================== rating option ================================================== */
    .rating-options {
        margin-top: 16px;
        h5{
            font-size: 1rem;
            font-weight: 600;
            color: #443c68;
            margin-bottom: 1rem;
        }
        .ab-row {
            display: flex;
            align-items: center;
            margin-bottom: 1.6em;
            .ab-col {
                display: flex;
                align-items: center;
                margin-right: 24px;
                
                .field-checkbox, .field-radiobutton{
                    margin-bottom: 0;
                }
                label{
                    margin-bottom: 0;
                }
                
            }
        }
    }
    
.p-dialog .p-dialog-content::-webkit-scrollbar{
 width: 8px;
}
    