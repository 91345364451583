.card-p{
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    overflow: hidden;
    position: relative;
    transition: box-shadow .3s ease-out .1s, transform .3s ease-out .1s, opacity .2s ease-out .1s;
    transform: translateZ(0);
    cursor: pointer;
}
.card-p-a{
display: block;
}
.card-p>a::before{
    opacity: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(10, 10, 10, 0.1) 0, rgba(10, 10, 10, 0) 60%);
    background: -webkit-linear-gradient(top, rgba(10, 10, 10, 0.1) 0, rgba(10, 10, 10, 0) 60%);
    background: linear-gradient(to bottom, rgba(10, 10, 10, 0.1) 0, rgba(10, 10, 10, 0) 60%);
    background: -ms-linear-gradient(top, rgba(10, 10, 10, 0.1) 0, rgba(10, 10, 10, 0) 60%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="rgba(10, 10, 10, 0.1)", endColorstr="rgba(10, 10, 10, 0)",GradientType=0 );
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    -ms-transition: opacity .2s ease;
    transition: opacity .2s ease;
}
.card-p:hover{
    box-shadow: 0 4px 12px rgba(0,0,0,.15);
    -moz-transform: translate(0, -4px);
    -ms-transform: translate(0, -4px);
    -webkit-transform: translate(0, -4px);
    transform: translate(0, -4px);
    z-index: 1;

}
.card-p>a:hover::before{
    opacity: 1;
}
.brand-logo-p{
    width: 6em;
    height: 6em;
    display: flex;
    align-items: center;
    margin: auto;
   
}
.brand-logo-p  img{
    width: 100%;
    height: auto;
}
.p-img{
    display: flex;
    margin: auto;
    width: 100%;
    overflow: hidden;
}
.p-img img{
    width: 100%;
    height: auto;
    margin: auto;
}
.p-text{
    padding: 16px;
}
.p-text span{
    font-size: .9375rem;
    line-height: 1rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #313131;
}
.p-text h2{
    font-size: .9375rem;
    margin-bottom: 8px;
    margin-top: 6px;
display: block;
font-weight: normal;
font-family: "Roboto", Arial, sans-serif;
line-height: 1.3;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
max-width: 100%;
height: 36.4px;
white-space: normal;
overflow: hidden;
display: block;
display: -webkit-box;
color: #737373;
font-weight: normal;
}
@media screen and (max-width: 576px){
    .card-p:nth-child(2n){
        margin-top: 20px;
        margin-bottom: -20px;
    }
    .brand-logo-p{
        width: 4em;
        height: 4em;
    }
    .p-img img{
        height: 112px;
        object-fit: inherit;
        margin: auto;
    }
    .p-text{
        padding: 0.5rem 0.7rem;
    }
    .p-text h2{
        font-size: .875rem !important;

    }
}
/*============= card 2 ================*/ 
.card-1-1{
    border-radius: 9px;
    width: 100%;
    aspect-ratio: 4/2.4;
    position: relative;
    height: 185px;
}
.card-1-1 a{
    display: block;
    height: 100%;
}
.card-1-1-img{
    height: 152px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 16px;
    color: #222;
    width: 50%;
    display: flex;
    
}
.card-1-1-img img{
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;

}
@media screen and (min-width:601px) and (max-width:997px) {
    .main-cat-list {
        justify-content: flex-start !important;
    }
}
@media screen and (max-width: 576px){
    .card-1-1 {
        border-radius: 9px;
        aspect-ratio: inherit;
        height: 103px;
    }
    .main-cat-list {
        margin: 0px 0;
        font-size: 14px !important;
        margin-top: 0 !important;
        justify-content: flex-start !important;
    }

    .main-cat-list li .act-sec-tab{
        font-size: 15px;
    }

    .main-cat-list li {
        padding: 16px 10px 0px;
        margin-right: 0;
    }

    .main-cat-list li:nth-child(2) .act-sec-tab{
        margin: 0;
    }
 
}
/*============ end ================= */ 

.product-card-full{
    width: 100%;
}
.pc-img{
    width: 100%;
    background-color: antiquewhite;
}
.pc-img img{
    width: 100%;
    height: auto;
}
.pc-text{
color: #222;
padding: 16px 0;
}
.pc-text h2{
    font-size: 1.175rem;
    text-overflow: ellipsis;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
 }
.pc-text p{
    font-size: 14px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    letter-spacing: -0.1px;
}
.pc-text span{
    font-size: 14px;
    color: #0c00b1;
    font-weight: 600;
    margin-bottom: 8px;
}

@media screen and (max-width: 1200px) {
    .brand-logo-p{
        width: 4em;
        height: 3em;
    }
    .p-text{
        padding: 12px;
    }
    .p-text span{
        font-size: .85rem;
    }
    .p-text h2{
        font-size: .75rem;
        line-height: 1.6;

    }
}
@media screen and (max-width: 576px) {
    .product-card-full{
        padding-bottom: 10px;
        
    }
    .product-card-full a{
        display: grid;
    }

    .pc-img{
        height: 132px;
        
    }
    .pc-img img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    .pc-text h2{
        width: 167px;
        font-size: 15px;
        max-width: 100%;
        margin-bottom:3px;
    }
 .pc-text p{
    width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    background:#fff;
    font-size: 12px;
    font-family: "SF UI Text Regular";
  }
  .pc-text span{
    margin-bottom: 4px !important;
  }
}