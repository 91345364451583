.row{
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.col-sm-12{
    flex: 0 0 100%;
    max-width: 100%; 
    
}
.col-sm-6{
    flex: 0 0 50%;
    max-width: 50%;
}