.cashback-popup .p-dialog .p-dialog-header {
    font-size: 27px;
    font-family: 'SF UI Text Bold';
    text-align: center;
    justify-content: center;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
    padding: 32px;
}
.cashback-popup .p-dialog{
    border-radius: 22px;
}
.cashback-popup .p-dialog .p-dialog-content {
    padding: 0 32px 32px;
}

.cashback-popup .p-dialog .p-dialog-footer {
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
    padding: 0 32px 32px;
}

.cashback-popup .p-sidebar-bottom {
    max-height: 90vh;
    height: fit-content;
    z-index: 99999 !important;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
}

.cashback-popup .p-sidebar .p-sidebar-header {
    display: block !important;
    font-size: 18px;
    font-family: 'SF UI Text Bold';
    text-align: center;
    justify-content: center;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
}

.cashback-popup .p-sidebar .p-sidebar-content {
    padding: 1rem;
    padding-top: 0rem !important;
    padding-bottom: 1rem !important;
}

.cashback-popup .p-sidebar-footer {

    padding: 1rem !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.14) !important;
}

::ng-deep .basic-info-popup .p-dialog {
    background-color: #fff;
    background-image: initial !important;
}

.steper {
    display: flex;
    margin: auto;
    margin-bottom: 16px;
    width: fit-content;
}

.steper li {
    display: flex;
    margin-right: 57px;
    position: relative;
}

.steper li div {
    display: grid;
    justify-content: center;
    justify-items: center;
}

.steper li {
    height: 25px;
    width: 25px;
    border: 2px solid #adadad;
    border-radius: 13.5px;
    color: rgba(36, 39, 44, 0.3);
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.steper li p {
    font-family: "SF UI Text Medium";
    font-size: 12px;
    margin-top: 4px;
}

.steper li::after {
    background: #f1f1f1;
    content: "";
    height: 2px;
    position: absolute;
    right: -59px;
    top: 10px;
    width: 57px;
}

.active-li {
    background-color: #1a374d !important;
    color: #fff !important;
}

.active-li::after {
    background-color: #1a374d !important;
}

.steper li:last-child {
    margin-right: 0;
}

.steper li:last-child::after {
    display: none;
}

.web-bg {
    background: #f7f7f7;
}

.dc-row {
    margin-bottom: 16px;
}

.dc-row h3 {
    font-size: 16px;
}

.dc-row:last-child {
    margin-bottom: 0px;
}

.dc {
    display: flex;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    justify-content: center;
    border-radius: 12px;
    padding: 8px;
    align-items: center;
    color: inherit;
    position: relative;
    cursor: pointer;
}

.dc:hover {
    box-shadow: 1px 1px 1px #ddd;
}

.dc-1 {
    width: 60px;
    height: 60px;
    margin-right: 12px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.dc-1 img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.dc-1 i {
    font-size: 28px;
}


.cc-l {
    border-radius: 50%;
    border: 1px solid #d2d2d2;
}

.dc-2 {
    width: calc(100% - 72px);
    height: fit-content;
}

.dc-2 h4 {
    font-size: 14px;
    margin-bottom: 4px;

}

.dc-2 p {
    font-size: 12px;
}

.dc-footer {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    margin-top: 16px;
    flex-wrap: wrap;
}

.dc-fbtn {
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
    color: #fff;
    border-radius: 4px;
    padding: 7px 32px 8px;
    font-family: 'SF UI Text Medium';
    font-size: 14px;
}

.cancel-btn {
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    color: #222;
    border-radius: 4px;
    padding: 7px 32px 8px;
    font-family: 'SF UI Text Medium';
    font-size: 14px;
    transition: 3s;
}

.cancel-btn:hover {
    opacity: .9;
}

.cover-strip-casback {
    min-height: 100vh;
    background-color: #fff;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
}

.cb-img {
    height: 400px;
    margin-left: 18%;
    margin-top: -12%;
}

.cashback-box {
    background-color: #fff;
    padding: 32px;
    max-width: 50%;
    margin: auto;
    border-radius: 9px;
    width: 410px;
    margin-left: auto;
    margin-right: 60px;
    margin-top: 57px;

    box-shadow: 0 2px 20px #0000001a;
}

.cashback-box h2 {
    font-size: 18px;
    font-family: "SF UI Text Semibold";
    color: #222;
    margin-bottom: 32px;
    text-align: center;
}

.dc-edit-card {
    min-height: 78px;
    color: #525f7f;
    border: 1px solid #c2c2c2;
    cursor: pointer;
}

.dc-edit-card i {
    font-size: 28px;
}

.dc-edit-card button {
    font-size: 22px;
    font-family: "SF UI Text Medium";
    background: transparent;
    color: #525f7f;
    border: none;
    margin-left: 12px;
}

.dc-edit {
    cursor: pointer;
}

.edit-bt {
    position: absolute;
    right: 8px;
    top: 8px;
    border: 1px solid #06c;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #06c;
}

.cb-autocomplete {
    width: 100%;
    min-height: 78px;
    color: #525f7f;
    border: 1px solid #c2c2c2;
}
.cb-autocomplete label {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    cursor: pointer;
}
.cb-autocomplete label span {
    font-size: 16px;
}

@media only screen and (max-width: 600px) {
    .cover-strip-casback {
        height: 120px;
        margin-left: -16px;
        margin-right: -16px;
        align-items: baseline;
    }
    .cover-strip-casback h2 {
        font-size: 24px;
    }

    .dc-edit {
        display: flex;
        width: 100%;
    }

    .dc-edit-card button {
        font-size: 14px;
    }

    .cashback-box {
        max-width: 100%;
        padding: 12px 0;
        box-shadow: none;
        margin: 0px 16px auto;
    }

    .cb-img {
        display: none;
    }
}

::ng-deep .basic-info-popup .p-dialog .p-dialog-content::-webkit-scrollbar {
    display: none;
}

.term-and-condition {
    width: 100%;
    margin-bottom: 4px;
}
.term-and-condition span {
    text-align: center;
    font-size: 12px;
    color: #222;
}
.term-and-condition span a {
    color: #06c;
}
.upload-file {
    width: 100%;
}

.upload-file label {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin: -12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.upload-file label span {
    font-size: 16px;
}

.upload-file img {
    padding: 0;
    height: 42px;
    opacity: .5;
}

.upload-file input {
    display: none;
}

.cashback-cover {
    background-color: transparent;
    min-height: 469px;
    padding: 32px;
    position: relative;
    margin-bottom: 32px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-color: #fff;
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
    
}
.cashback-cover h2 {
    color: #343a40;
    font-size: 22px;
    margin-bottom: 22px;
}
.cashback-cover p {
    color: #6c757d;
}
.active-cashback-cover{
    background-image:none;
    min-height: 250px;
    background-color: #fff;
    
}
.cover-bg{
    box-shadow: none;
    min-height: fit-content;
    padding: 0;
    border-radius: 22px;
    overflow: hidden;
    margin-top: 16px;
    margin-bottom: 16px;
    
}
.cover-bg img{
    width: 100%;
}
.section-div-2 {
    margin-bottom: 32px;
   
}
.section-div-2 ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    width: fit-content;
    margin: auto;
}
.section-div-2 .brand-cashback-card {
    border-radius: 16px;
    width: 100%;
    display: flex;
    overflow: hidden;
    cursor: pointer;
    
}
.section-div-2 .brand-cashback-card img {
    width: 100%;
}
.cb-brand {
    width: 160px;
    display: grid;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.logo {
    width: 120px;
    margin-bottom: 24px;
}

.logo img {
    max-width: 100%;
}

.cb-text {
    background-color: aliceblue;
    padding: 12px;
    border-radius: 22px;
    font-family: 'SF UI Text Medium';
}

.cb-cat {
    width: calc(100% - 200px);
}

.cb-cat-ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}

.cb-cat-box {
    text-align: center;
    position: relative;
}

.cashback-i-chip {
    display: flex;
    background-color: #df5e00;
    padding: 3px 12px;
    width: fit-content;
    border-radius: 7px;
    margin: auto;
    color: #fff;
}

.cb-cat-img {
    width: 100%;
    aspect-ratio: 8/8;
    border-radius: 0.5rem;
    position: relative;
}


.cb-cat-img img {
    max-width: 100%;
}

/* Cashback list */

.cashbacks {
    background-color: #fff;
    padding: 32px;
    border-radius: 22px;
}

.cashbacks h2 {
    font-size: 18px;
    font-family: 'SF UI Text Semibold';
}

.cb-ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 0px;
    margin-bottom: 0;
}

.cb-card {
    border: 1px solid #e9ecef;
    border-radius: 9px;
    padding: 16px;
    padding-bottom: 0px;
    position: relative;
    overflow: hidden;
}

.cb-header {
    display: flex;
    justify-content: space-between;
}

.cb-header p {
    font-size: 14px;
}

.cb-header p span {
    font-size: 14px;
    font-family: 'SF UI Text Semibold';
}

.cb-items {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    gap: 8px;
}

.cb-item-text {
    display: grid;
}

.cb-item-text span:first-child {
    font-family: "SF UI Text Bold";
    font-size: 12px;
    display: flex;

}

.cb-item-text p {
    font-family: 'SF UI Text Regular';
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
}

.wait-cb-amount {
    background: #222;
    height: 86px;
    aspect-ratio: 8/8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: #fff;
    font-family: 'SF UI Text Medium';
    font-size: 16px;
    margin-bottom: 34px;
    margin-top: 32px;
}
.active-wait-cb-amount{
    background: #06b206;
}
.cd-status-strip {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 14px;
    color: #222;
    align-items: center;
}

.cd-status-strip p {
    text-transform: capitalize;
}

.cd-status-strip p span {
    font-family: 'SF UI Text Semibold';
}

.cd-status-strip a {
    padding: 5px 12px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #06c;
    text-transform: capitalize;
    font-family: 'SF UI Text Semibold';
    font-size: 13px;
}

::ng-deep .owl-theme .owl-nav [class*=owl-] {
    width: 40px;
    height: 40px;
    display: flex;
}

@media screen and (max-width: 600px) {
    .cashbacks {
        padding: 0;
        background-color: transparent;
    }

    .cb-card {
        background-color: #fff;
    }

    .cb-header {
        margin-bottom: 16px;
    }

    .wait-cb-amount {
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .cb-col .detail p {
        font-size: 13px;
    }

    .cb-items {
        flex-wrap: wrap;
    }

    .cb-item-text {
        width: 100%;
    }

    .cd-status-strip {
        width: 100%;
        flex-wrap: wrap;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .cd-status-strip a {
        width: fit-content;
        margin-left: auto;
        margin-top: 12px;
    }

    .cb-ul {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* cashback info */
.cashback-info {
    /* background-color: #fff;*/
    margin: auto;
    width: fit-content;
    text-align: center;

}

.cashback-info h2 {
    font-size: 32px;
    font-family: "SF UI Text Bold";
    margin-bottom: 32px;
    margin-top: 50px;

}

.cashback-info-step {
    display: grid;
    width: 100%;
    padding: 0px;
    gap: 24px;
    grid-template-columns: repeat(4, 1fr);
    margin: auto;
    margin-bottom: 24px;
}

.cashback-item {
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
}

.cashback-img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    display: flex;
    font-size: 3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;

}

.cashback-text {
    text-align: center;
    padding-top: 24px;
}

.cashback-text span {
    font-size: 14px;
    font-family: 'SF UI Text Bold';
}

.cashback-text p {
    font-size: 16px;
    font-family: 'SF UI Text Medium';
}

.cashback-text span:last-child {
    font-size: 18px;
    font-family: 'SF UI Text Regular';
    display: flex;
    text-align: center;
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
    color: #222;
    margin-top: 16px;
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 600px) {
    .cashback-info h2 {
        font-size: 22px;
        margin-bottom: 18px;
        margin-top: 24px;
    }
    .cashback-info-step{
        grid-template-columns: repeat(2, 1fr);
    }
    .cashback-item{
        padding: 16px;
    }
    
    .cashback-cover {
        margin-left: -16px;
        margin-right: -16px;
        border-radius: 0;
        margin-bottom: 0;
    }

    .cashback-info-step {
        width: 100%;
        gap: 8px;
    }
    .cover-bg img{
        width: 108%;
    }
    .cashback-img {
        width: 50px;
        height: fit-content;
        margin: auto;
        margin-bottom: 6px;
        
    }
    .cashback-img img{
        width: 100%;
    } 
    .cashback-text{
        padding-top: 16px;
    }
    .cashback-text span{
        font-size: 16px;
        color: #06c;
    }
    .cashback-text span:last-child{
        font-size: 14px;
        margin-top: 4px;
    }
}

/* end cashback info*/

/* Brands */
.brand-section {
    width: 100%;
    overflow: hidden;
    padding: 0 32px;
}

.brand-list {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding-bottom: 0px;
    border-radius: 8px;
    border: 1px solid transparent;
    overflow: hidden;
    padding: 2px;
}

.brand-list-img {
    padding: 10px 0px;
    width: 68%;
    margin: auto;
}

.img-card {
    min-height: 114px;
    display: flex;
    align-items: center;
    border: 1px solid rgb(0 0 0 / 10%);
    box-shadow: 0 1px 3px 0 rgba(36, 39, 44, .15);
}

.img-card:hover {
    box-shadow: 0 3px 6px rgba(33, 30, 34, 0.2);
    background-color: #fff;
    border-color: #1a374d;
}

.brand-list-img img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    height: auto;
    width: fit-content;
}

.brand-list-ul-mobile ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background-color: #fff;
    margin-bottom: 0;
    margin-top: 0;
    padding: 16px 16px 6px 16px;
}

.brand-list-ul-mobile ul li {
    min-height: 75px;
    width: 50%;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 0px;
}

.brand-list-ul-mobile ul li:nth-of-type(2n) {
    padding-right: 0px;
}

.brand-list-ul-mobile ul li a {
    text-align: center;
    padding: 0px 0;
    border: 1px solid #d5d5d5;
    margin: auto;
    align-items: center;
    display: -ms-grid;
    display: block;
    width: 100%;
    height: fit-content;
    border-radius: 6px;
    overflow: hidden;
    padding: 12px;
    background-color: #fff;
}

.brand-sec-ul {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    display: grid;
    gap: 8px;
}

.brand-card {
    min-height: 114px;
    max-height: 126px;
}

.section-footer {
    text-align: right;
}

@media only screen and (max-width: 600px) {
    .cashback-cover {
        margin-left: -15px;
        margin-right: -15px;
        margin-top: -15px;
        min-height: 120px;
        margin-bottom: 16px;
    }

    .cashbacks {
        margin-bottom: 0;
    }
    .cashbacks h2 {
        font-size: 16px;
        margin-top: 16px;
    }
    .section-div-2 {
        margin-bottom: 0px;
        margin-left: -16px;
        margin-right: -16px;
        
    }
    .section-div-2  ul {
        display: flex;
        overflow: hidden;
        overflow-x: scroll;
        padding-left: 16px;
        width: 100%;
    }
    .section-div-2 .brand-cashback-card {
        width: 100%;
        border-radius: 9px;
    }

    .section-div {
        margin-left: -16px;
        margin-right: -16px;
        border-radius: 0;
        padding: 16px;
        margin-bottom: 0;
        padding-top: 0px;
    }

    .cb-ul li {
        margin-bottom: 0;
    }

    .brand-sec {
        margin-left: -16px;
        margin-right: -16px;
    }

    .brand-sec-ul {
        grid-template-columns: repeat(3, 1fr);
        display: grid;
        border: 1px solid #f2f2f2;
    }

    .brand-li {
        border: 1px solid #f2f2f2;
        border-left: 0;
        border-top: 0;
    }

    .brand-li:nth-child(3n+3) {
        border-right: 0;
    }

    .brand-card {
        width: 100%;
        display: grid;
        text-align: center;
        padding: 14px;
        min-height: 86px;
    }

    .brand-card img {
        display: grid;
        text-align: center;
        padding: 4px;
        margin: auto;
        max-height: 50px;
        width: 100%;
    }

    .brand-card span {
        font-family: "SF UI Text Regular";
        color: inherit;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 4px;
        color: #24272c;
        font-size: 12px;
    }

    .dc-row {
        gap: 0 !important;
    }

    .warrning-message {
        margin-top: 2px !important;
    }

    .p-float-note {
        margin-top: 2px !important;
        display: inline !important;
    }

    .upload-file {
        padding: 10px 12px;
    }

    .upload-file label span {
        font-size: 14px;
    }

    .upload-file label i {
        font-size: 32px !important;

    }

    .upload-file {
        width: 100% !important;
    }

    .term-and-condition span {
            font-size: 10px;
        }
    
}


.p-float-label input:focus + label,
.p-float-label input.p-filled + label,
.p-float-label textarea:focus + label,
.p-float-label textarea.p-filled + label,
.p-float-label .p-inputwrapper-focus + label,
.p-float-label .p-inputwrapper-filled + label {
    font-family: 'SF UI Text Medium' !important;
    top: 2px !important;
    background-color: #fff;
    padding: 0 3px;
}

::-webkit-input-placeholder {
    font-size: 13.5px;
}

.invoice-shake {
    color: red;
    animation: bounce_shake 1.5s ease-out infinite;
}

@keyframes bounce_shake {
    0% {
        transform: scale(1, 1) translateY(0);
    }

    10% {
        transform: scale(1.1, .9) translateY(0);
    }

    30% {
        transform: scale(.9, 1.1) translateY(-5px);
    }

    32% {
        transform: scale(1.2) translateY(-5px);
    }

    35%,
    40% {
        transform: scale(1.2) translateY(-5px) rotate(-20deg)
    }

    45%,
    50% {
        transform: scale(1.2) translateY(-5px) rotate(20deg)
    }

    60% {
        transform: scale(1.05, .95) translateY(0);
    }

    70% {
        transform: scale(1.05, .95) translateY(0);
    }

    80% {
        transform: scale(1, 1) translateY(-3px);
    }

    95% {
        transform: scale(1, 1) translateY(0);
    }

    100% {
        transform: scale(1, 1) translateY(0);
    }
}


/* Detail */
.cashback-detail-cover {
    background-color: transparent;
    min-height: 260px;
    padding: 32px;
    position: relative;
    margin-bottom: 32px;
    box-shadow: 0px 3px 4px #dedede;
}

.earned-cashback {
    width: fit-content;
    height: fit-content;
    margin: auto;
}

.cb-amout {
    text-align: center;
    background-color: #f6fff8;
    padding: 11px;
    color: #06b206;
    border: 1px solid #06b206;
    border-radius: 12px;
    margin-bottom: 6px;
}
.cb-amout span{
    font-size: 12px;
}
.cb-amout  p{
    font-size: 24px;
    font-family: 'SF UI Text Semibold';  
}

.cb-t-row {
    display: flex;
    text-align: center;
    gap:16px;
}

.cb-t-c-amount {
    font-size: 12px;
}
.cb-t-c-amount p {
    font-family: 'SF UI Text Medium';
}
.cb-t-c-amount span {
    font-size: 13.5px;
}

.cb-t-b-amount {
    font-size: 12px;
}
.cb-t-b-amount  p {
    font-family: 'SF UI Text Medium';
}
.cb-t-b-amount span {
    font-size: 13.5px;
}

.cover-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px 32px;

}

.cb-data-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 0px 32px 32px;
}

.cb-col {
    border: 1px solid #e9ecef;
    padding: 16px;
    border-radius: 9px;
}
.cb-col .title {
    font-size: 12px;
    font-family: 'SF UI Text Semibold';
}
.cb-col .detail {
    display: grid;
}
.cb-col p:first-child {
    font-family: 'SF UI Text Medium';
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
}
.cb-col p:first-child {
    font-size: 14px;
}
.cb-col .detail div {
    width: fit-content;
    margin: auto;
}
.cb-col .detail img {
    height: 60px;
    margin: auto;
    margin-top: 12px;
}
.count-down-timer {
    box-shadow: 2px 6px 9px 2px rgb(0 0 0 / 20%);
    text-align: center;
    background-color: #fff;
    max-width: 400px;
    margin: 20px auto;
    color: #222;
    border-radius: 6px;
    padding: 10px;
    font-family: "SF UI Text Medium";
}
.count-down-timer  p {
    margin: 5px 0 15px 0;
}

.count-down-timer .wrapper  .description,
.times {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 8px));
    grid-column-gap: 10px;
}
.count-down-timer .wrapper .description  p {
        margin: 0;
        font-family: 'SF UI Text Medium';
    }
    .count-down-timer .wrapper .times p {
            letter-spacing: -5px;
            position: relative;
            margin: 0;
            font: normal 40px 'SF UI Text Medium';

            
        }
        .count-down-timer .wrapper .times p ::ng-deep img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            display: block;
            height: 30px;
        }
@media screen and (max-width: 600px) {
    .cashback-detail-cover{
        margin-left: -16px;
        margin-right: -16px;
    }
    .count-down-timer {
        margin: 32px 3px;
        font-size: 14px;
        font-family: "SF UI Text Medium";
    }

    .cashback-cover {
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 0;
    }

    .cover-footer {
        bottom: 0;
        padding: 16px;
    }

    .cb-data-row {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        padding: 0px;
    }

    .cb-col {
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
    }
    .cb-col .title {
        font-size: 15px;
        font-family: "SF UI Text Bold";
    }
    .cb-col .detail p:first-child {
            font-family: "SF UI Text Semibold";
            font-size: 14px;
        }
    .cb-col .detail  p {
        font-size: 13px;
    }
    .cb-col .detail span {
        size: 13px;
    }
    .cb-col .detail div {
        max-width: 100%;
        margin: auto;
        overflow: hidden;
        width: 100%;
    }
}

.upload-file {
    width: 100%;
}

.upload-file label {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin: -12px;
    display: grid;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    margin-top: 12px;

}

.upload-file label span {
    font-size: 16px;

}

.upload-file img {
    padding: 0;
    height: 42px !important;
    opacity: .5;
    margin-top: 0 !important;
}

.upload-file input {
    display: none;
}

.cashback-products {
    min-height: 200px;
    min-width: 100%;
    display: flex;
}

.no-p-msg {
    text-align: center;
    font-family: 'SF UI Text Semibold';
    margin: auto;
    height: fit-content;
}

/* product CSS */
.cashback-products {
    min-width: 100%;
}

.p-count {
    padding: 0 24px 14px;
    font-size: 18px;
    font-family: 'SF UI Text Medium';
}

.c-product-list {
    padding: 0px 24px 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
    gap: 8px;
    margin-bottom: 32px;
}
.c-product-card {
    transition: box-shadow 83ms;
    border: 1px solid #dbdbdb;
    border-radius: 12px;
    position: relative;
    height: 100%;
}

.c-product-img {
    margin: auto;
    position: relative;
    height: 12.5rem;
    display: flex;
}

.c-product-img img {
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    padding: 6px 12px;
}

.c-product-content {
    padding: 0px 10px 6px;
    padding-bottom: 16px !important;
}
.c-product-brand {
    font-family: "SF UI Text Medium";
    color: #222;
    font-size: 14px;
    line-height: 16.71px;
    margin-bottom: 7px;
    width: 71.5%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.c-product-name {
    color: #222;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 2px;
}

.c-product-sku {
    font-size: 12px;
    font-family: "SF UI Text Medium";
    margin-bottom: 4px;
}

.c-product-price {
    color: #b80000;
    ;
}

.c-product-price .key {
    color: #222;
}

.c-product-price s {
    color: #838383;
    font-size: 12px;
}

.c-product-price .dis {
    color: #28a745;
}

.c-product-qty {
    font-size: 12px;
}

.c-product-t-amout {
    font-family: "SF UI Text Medium";
}
.c-product-t-amout span {
    font-family: "SF UI Text Regular";
}

.c-product-cb-amount {
    margin-top: 6px;
    font-family: "SF UI Text Medium";
}
.c-product-cb-amount span {
    font-family: "SF UI Text Regular";
}

.selected-product {
    width: 80%;
    height: 200px;
    display: flex;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    margin: auto;
    margin-top: 12px;
    margin-bottom: 12px;
}

.selected-product img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.inVoice-img {
    width: fit-content;
    margin: auto;
    margin-top: 12px;
    justify-content: center;
    display: grid;
}
.inVoice-img a {
    width: fit-content;
    margin: auto;
    display: grid;
}

.inVoice-img img {
    width: 60px;
    margin: auto;
    margin-bottom: 12px;
}

.error-span {
    color: #b80000;
    font-size: 12px;
    margin-top: 8px;
}

.suggestions-strip {
    font-size: 12px;
    text-align: right;
    position: absolute;
    top: 10px;
    right: 0;

}

.suggestions-strip span {
    color: #28a745;
}

.pro-edit-icon {
    width: 35px;
    height: 35px;
    border: 1px solid #007bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007bff;
    cursor: pointer;
    position: absolute;
    right: 12px;
    bottom: 12px;
    visibility: hidden;
    opacity: 0;
}

.c-product-card:hover .pro-edit-icon {
    visibility: visible;
    opacity: 1;
}

.product-not-found {
    display: block;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
    text-align: right;
}
.cb-t-row-p{
    font-size: 14px;
}
@media only screen and (max-width: 1600px) {}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1200px) {
    .c-product-box {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding-right: 33.33px;
        padding-bottom: 33.33px;
    }
}

@media only screen and (max-width: 997px) {
    .c-product-box {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 16px;
        padding-bottom: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .p-count{
        margin-top: 16px;
        padding: 0px 16px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }
    .c-product-list{
        margin-right: -16px;
        margin-left: -16px;
        padding: 0px 16px 16px;
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr)) !important;
    }
    .c-product-box {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px;

    }
    .c-product-name{
        font-size:12px;
    }
    .c-product-sku{
        font-size: 11px;
    }
    .c-product-price{
            font-size: 13.5px;
            font-family: 'SF UI Text Medium';
       
    }
    .c-product-price .dis{
        font-size: 12px;
    }
    .c-product-t-amout{
        font-size: 12px;
    }
    .cb-t-row-p{
        font-size: 12px;
    }
    .c-product-cb-amount{
        background-color: #28a745;
        color: #fff;
        padding: 5px 12px;
        border-radius: 6px;
        text-align: center;
        font-size: 12px;
    }
    .apo-brand-log {
        display: none;
    }

    .data-row-contect {
        flex-wrap: wrap;
    }

    .data-detail h2 {
        font-size: 16px;
    }

    .data-detail {
        font-size: 14px;
    }

    .data-info {
        font-size: 14px;
        text-align: right;
    }

    .data-contect-parson {
        font-size: 14px;
    }
}